import * as yup from "yup";

export const subscriptionSchema = yup.object().shape({
  contactInfo: yup.string().required('Contact info is required'),
  vatNumber: yup.string().optional(),
  setupFeePeriod: yup.string().optional(),
  startupAcademyFeePeriod: yup.string().optional(),
  monthlyFee: yup.number().required('Monthly fee period is required'),
  startDate:yup.string().required("Start date is required"),
  endDate:yup.string().required("End date is required"),
  paymentPeriodicity:yup.string().optional(),
  comment: yup.string().required('Comment is required'),
  isTranslationEnabled: yup.boolean().required('Enable Translation is required'),
  proAccount: yup.string().required('Pro account is required')
}).required();
