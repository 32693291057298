import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
const languages = [
    { label: "Albanian", code: "sq" },
    { label: "Romanian", code: "ro" },
    { label: "Polish", code: "pl" },
    { label: "French", code: "fr" },
    { label: "Italian", code: "it" },
    { label: "Swedish", code: "sv" },
    { label: "Portuguese", code: "pt" },
    { label: "Norwegian", code: "no" },
    { label: "English", code: "en" },
    { label: "Dutch", code: "nl" },
];
// Extract supported language codes
const supportedLanguageCodes = languages.map(lang => lang.code);

// Detect the browser language
const browserLanguage = navigator.language.split("-")[0]; // Extract language code (e.g., 'en-US' -> 'en')

// Default to English if browser language is not supported
const defaultLanguage = supportedLanguageCodes.includes(browserLanguage) ? browserLanguage : "en";

i18n.use(detector)
    .use(initReactI18next)
    .init({
    fallbackLng: 'en',
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        nl: {
            translations: require('./locales/nl/translations.json')
        }
    },
    lng: defaultLanguage,
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['en','nl'];

export default i18n;