import {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {AdminDashboardLayout} from "../../../components/layouts/AdminDashboardLayout";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import NoDataFound from "../../../components/NoData/NoDataFound";
import {adminService} from "../../../services/admin.service";
import {IPaginationInfo} from "../../../interfaces/pagination-info.interface";
import {Toast} from "../../../utils/Toast";
import {images} from "../../../assets/images";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {subscriptionSchema} from "../createorganisation/validations/subscription.validation";
import DatePicker from "react-datepicker";
import {useTranslation} from "react-i18next";

export default function AdminDashboardOrganizations() {
  const [organizations, setOrganizations] = useState<any[]>([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editSubscription, setEditSubscription] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo>({
    pages: 0,
    perPage: 10,
    currentPage: 1,
    totalRecords: 0,
  });
  const { t } = useTranslation('', { keyPrefix: 'AdminDashboardOrganizations' });

  const {
    control,
    register,
    setError,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(subscriptionSchema),
    defaultValues:{
      
    }
  });

  useEffect(() => {
    getOrganizations(paginationInfo.currentPage);
  }, []);

  const getOrganizations = async (page: number) => {
    setIsLoading(true);
    const res: any = await adminService.getOrganizations({ page, limit: 20 });

    if (res.statusCode == 200) {
      setPaginationInfo(res.payload.organizations.paginationInfo);
        setOrganizations([...organizations,...res.payload.organizations.records]);
    } else {
      Toast.fire({
        icon: "warning",
        title: res.message,
      });
    }
    setIsLoading(false);
  };

  const handleStartDateChange = (data: any) => {
    setStartDate(data);
    setValue("startDate", data)
  };
  const handleEndDateChange = (data: any) => {
    setEndDate(data);
    setValue("endDate", data)
  };

  const subscriptionSubmit = async (data: any) => {
    setIsSubmit(true);
    data.organization = organizations[editSubscription]._id;
    const res: any = await adminService.subscription(data);
    if (res.statusCode == 200) {
      const temOrganizations = [...organizations];
      temOrganizations[editSubscription].subscription =
        {...res.payload.subscription};
      temOrganizations[editSubscription].isTranslationEnabled = res.payload.subscription.isTranslationEnabled;
      setOrganizations([...temOrganizations]);
      Toast.fire({
        icon: "success",
        title: res.message,
      });
      handleClose();
    } else {
      Toast.fire({
        icon: "warning",
        title: res.message,
      });
    }
    setIsSubmit(false);
  };
  console.log("getValues",getValues())
  return (
    <AdminDashboardLayout>
      {isLoading && <FullPageLoader />}
      <div className="up-usersholer">
        <div className="up-userfilterholder">
          <div className="up-userspagetitle">
            <h2>{t('organizations')}</h2>
          </div>
        </div>
        <div className="up-userstable">
          <div className="up-usertableholder">
            {organizations && organizations.length > 0 && (
              <InfiniteScroll
                dataLength={organizations!.length} //This is important field to render the next data
                next={() => getOrganizations(paginationInfo?.currentPage! + 1)}
                hasMore={
                  organizations!.length !== paginationInfo?.totalRecords!
                }
                loader={<h4>{t('loading')}</h4>}
                endMessage={
                  <p className="up-allpostseen" style={{ textAlign: "center" }}>
                    <b>{t('noMoreOrganizations')}</b>
                  </p>
                }
              >
                <table className="up-tabletheme">
                  <thead>
                    <tr>
                      <th>{t('name')}</th>
                      <th>{t('email')}</th>
                      <th>{t('Type')}</th>
                      <th>{t('proAccount')}</th>
                      <th>{t('membersCount')}</th>
                      <th>{t('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {organizations.map((organization, index) => (
                      <tr key={index}>
                        <td>
                          <div className="up-usertabledetail">
                            <figure>
                              <img
                                style={{ cursor: "pointer" }}
                                src={
                                  organization?.avatar ||
                                  images.OrganiserCompanyLogo
                                }
                                alt="user image"
                              />
                            </figure>
                            <h4 style={{ cursor: "pointer" }}>
                              {organization?.name}
                            </h4>
                          </div>
                        </td>
                        <td>{organization?.email ?? "-"}</td>
                        <td>{organization?.type?.name}</td>
                        <td>
                          {organization?.subscription?.proAccount ?? "NO"}
                        </td>
                        <td>{organization?.members?.length}</td>
                        <td>
                          <button
                            type="button"
                            className="up-btn"
                            onClick={() => {
                                reset();
                                setStartDate(null)
                                setEndDate(null)
                              setEditSubscription(index);
                              if (organization.subscription) {
                                for (let [k, v] of Object.entries(
                                  organization.subscription
                                )) {
                                  setValue(k, v);
                                }
                                setStartDate(new Date(organization.subscription.startDate))
                                setEndDate(new Date(organization.subscription.endDate))
                              }else{
                                setValue("proAccount", "NO")
                              }
                              setValue("isTranslationEnabled", organization.isTranslationEnabled)
                              handleShow();
                            }}
                          >
                            {t('update')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            )}
            {organizations && organizations.length === 0 && <NoDataFound />}
          </div>
        </div>
      </div>
      <Modal
        className="up-thememodal up-modalreport"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="up-modalheader" closeButton>
          <Modal.Title>{t('updateSubscription')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
          <div className="up-modalcontentholder">
            <form
              className="up-formtheme up-formcreateevent up-formorgnizationadmin"
              onSubmit={handleSubmit(subscriptionSubmit)}
            >
              <fieldset>
                <div className="up-createeventcontent">
                <div className="form-group">
                    <label>{t('proAccount')}</label>
                    <div className="up-select">
                      <select value={getValues().proAccount} onChange={(e) => {setValue("proAccount", e.target.value, {shouldDirty: true, shouldTouch: true, shouldValidate: true})}}>
                        <option value="NO">{t('no')}</option>
                        <option value="YES">{t('yes')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t('contactInfo')}</label>
                    <textarea
                      {...register("contactInfo")}
                      // type="text"
                      name="contactInfo"
                      className="form-control"
                      placeholder={t('contactInfo')}
                    >
                      </textarea>
                    <small id="content" className="form-text text-muted">
                      {errors.contactInfo?.message}
                    </small>
                  </div>
                  <div className="form-group">
                    <label>{t('VATNumber')}</label>
                    <input
                      {...register("vatNumber")}
                      type="text"
                      name="vatNumber"
                      className="form-control"
                      placeholder={t('VATNumber')}
                      disabled={getValues().proAccount == "NO"}
                    />
                    <small id="content" className="form-text text-muted">
                      {errors.vatNumber?.message}
                    </small>
                  </div>

                  <div className="form-group">
                    <label>{t('setupFeePeriod')}</label>
                    <input
                      {...register("setupFeePeriod")}
                      type="number"
                      name="setupFeePeriod"
                      className="form-control"
                      placeholder={t('setupFeePeriod')}
                      disabled={getValues().proAccount == "NO"}
                    />
                    <small id="content" className="form-text text-muted">
                      {errors.setupFeePeriod?.message}
                    </small>
                  </div>

                  <div className="form-group">
                    <label>{t('startupAcademyFeePeriod')}</label>
                    <input
                      {...register("startupAcademyFeePeriod")}
                      type="number"
                      name="startupAcademyFeePeriod"
                      className="form-control"
                      placeholder={t('startupAcademyFeePeriod')}
                      disabled={getValues().proAccount == "NO"}
                    />
                    <small id="content" className="form-text text-muted">
                      {errors.startupAcademyFeePeriod?.message}
                    </small>
                  </div>

                  <div className="form-group">
                    <label>{t('monthlyFee')}</label>
                    <input
                      {...register("monthlyFee")}
                      type="number"
                      name="monthlyFee"
                      className="form-control"
                      placeholder={t('monthlyFee')}
                    />
                    <small id="content" className="form-text text-muted">
                      {errors.monthlyFee?.message}
                    </small>
                  </div>

                  <div className="form-group">
                    <label>{t('paymentPeriodicity')}</label>
                    <input
                      {...register("paymentPeriodicity")}
                      type="text"
                      name="paymentPeriodicity"
                      className="form-control"
                      placeholder={t('paymentPeriodicity')}
                      disabled={getValues().proAccount == "NO"}
                    />
                    <small id="content" className="form-text text-muted">
                      {errors.paymentPeriodicity?.message}
                    </small>
                  </div>

                  <div className="form-group">
                    <label>{t('comment')}</label>
                    <textarea
                      {...register("comment")}
                      // type="text"
                      name="comment"
                      className="form-control"
                      placeholder={t('comment')}
                    >
                    </textarea>
                    <small id="content" className="form-text text-muted">
                      {errors.comment?.message}
                    </small>
                  </div>

                  <div className="up-formtwocols">
                    <div className="up-inputsholder">
                      <label>{t('startDate')}</label>
                      <DatePicker
                        {...register("startDate")}
                        selected={startDate}
                        placeholderText="yyyy/mm/dd"
                        // minDate={moment().toDate()}
                        onChange={handleStartDateChange}
                        dateFormat="yyyy-MM-dd"
                      />
                      <small id="content" className="form-text text-muted">
                        {errors.startDate?.message}
                      </small>
                    </div>
                    <div className="up-inputsholder">
                      <label>{t('endDate')}</label>
                      <DatePicker
                        {...register("endDate")}
                        selected={endDate}
                        // minDate={startDate}
                        placeholderText="yyyy/mm/dd"
                        onChange={handleEndDateChange}
                        dateFormat="yyyy-MM-dd"
                      />
                      <small id="content" className="form-text text-muted">
                        {errors.endDate?.message}
                      </small>
                    </div>
                  </div>

                  <div className="form-group up-togglealignflex">
                    <label>{t('enableTranslation')}</label>
                    <div className="up-togglebutton">
                      <input {...register("isTranslationEnabled")} type="checkbox" name="isTranslationEnabled" id="up-isTranslationEnabled" />
                      <label htmlFor="up-isTranslationEnabled"></label>
                    </div>
                    <small id="content" className="form-text text-muted">
                      {errors.isTranslationEnabled?.message}
                    </small>
                  </div>

                  <button
                    disabled={isSubmit}
                    type="submit"
                    className="up-btn up-btnwithloader"
                  >
                    {t('update')} {isSubmit && <div className="lds-dual-ring"></div>}
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </AdminDashboardLayout>
  );
}
