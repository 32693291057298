import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router";
import {IIdFromUrl} from "../../interfaces/id-from-url.interface";
import {dashboardService} from "../../services/dashboard.service";
import {Toast} from "../../utils/Toast";
import {DashboardLayout} from "../layouts/DashboardLayout";
import Image from "../../assets/images/d-icon/icon.svg";
import {useDebounce} from "../../hooks/use-debounce.hook";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {groupService} from "../../services/group.service";
import {IGroup} from "../../features/organisations/createorganisation/interfaces/group.interface";
import "react-datepicker/dist/react-datepicker.css";
import NoDataFound from "../NoData/NoDataFound";
import moment from "moment";
import {IPaginationInfo} from "../../interfaces/pagination-info.interface";
import {CSVLink} from "react-csv";
import {EPrivacy} from "../../features/auth/pages/signup/interfaces/drop-down.enums";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";
import {
    IOrganizationRequestedMembers, IOrganizationRole
} from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import Swal from "sweetalert2";
import {images} from "../../assets/images";
import {organisationService} from "../../services/organisation.service";
import {MemberRoleChangeModal} from "../../features/ownorganisation/components/MemberRoleChangeModal";
import {ValidTillModal} from "../../features/ownorganisation/components/ValidTillModal";
import {AssignUserFieldsModal} from "../../features/profile/components/AssignUserFieldsModal";
import {UpdateUserGroupModal} from "../../features/profile/components/UpdateUserGroupModal";
import {IUserField} from "../../interfaces/user-fields.interface";
import {useSelector} from "react-redux";
import {getUser} from "../../features/auth/slice/user.selector";


export default function DashboardUsers() {
    const [memberVisit, setMemberVisit] = useState<any[]>([]);
    const [search, setSearch] = useState("");
    const [userFieldSearch, setUserFieldSearch] = useState("")
    const [organizationGroups, setOrganizationGroups] = useState<IGroup[]>([]);
    const [isLoading, setIsloading] = useState(true);
    const [paginationInfoViews, setPaginationInfoViews] = useState<IPaginationInfo>();
    const [selectedGroup, setSelectedGroup] = useState(-69);
    const [fieldSelected, setFieldSelected] = useState(-69);
    const [csvUsers, setCSVUsers] = useState<any[]>([]);
    const [currentDataCSV, setCurrentDataCSV] = useState<any[]>([]);
    const [debounceSearch] = useDebounce(search, 300);
    const [debounceUserFieldSearch] = useDebounce(userFieldSearch, 300);
    let { id } = useParams<IIdFromUrl>();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [organizationFields, setOrganizationFields] = useState<IUserField[]>([]);
    const [memberSelectedForRoleChange, setMemberSelectedForRoleChange] = useState<any>()
    const [showMemberRoleChangeModal, setShowMemberRoleChangeModal] = useState(false);
    const [organizationRoles, setOrganizationRoles] = useState<IOrganizationRole[]>([])
    const [openValidTillModal, setOpenValidTillModal] = useState<boolean>(false)
    const [showFieldsModal, setShowFieldsModal] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);

    const { t } = useTranslation('', { keyPrefix: 'DashboardUsers' });
    const csvLink: any = useRef()
    const currentDataLink: any = useRef()
    const location = useLocation();
    const [organizationName, setOrganizationName] = useState(location?.search.replace('?', ''));
    const history = useHistory();
    const currentUser = useSelector(getUser);
    useEffect(() => {
        // getDashboardVisits(1, '', 20, false, fieldSelected.toString(), debounceUserFieldSearch)
        getOrganizationGroups(1, '')
        getOrganizationRoles(id)
        getOrganizationFields()
    }, [])
    useEffect(() => {
        if (csvUsers && csvUsers.length > 0) {
            csvLink?.current?.link?.click();
            setCSVUsers([]);
        }
    }, [csvUsers])
    useEffect(() => {
        if (currentDataCSV && currentDataCSV.length > 0) {
            currentDataLink?.current?.link?.click();
            setCurrentDataCSV([]);
        }
    }, [currentDataCSV])
    useEffect(() => {
        getDashboardVisits(1, debounceSearch, 20,false, fieldSelected.toString(), debounceUserFieldSearch, selectedGroup.toString());
      }, [debounceSearch,debounceUserFieldSearch]);
    const getOrganizationRoles = async (id: string) => {
        const res: any = await organisationService.getOrganizationRoles(id);
        if (res.statusCode == 200) {
            for (const iterator of res.payload.organizationRoles) {
                iterator.checked = false;
            }
            setOrganizationRoles(res.payload.organizationRoles)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getOrganizationFields = async () => {
        const res: any = await organisationService.getOrganizationFields(id);
        if (res.statusCode == 200) {
            setOrganizationFields([{_id: -69, label: 'Select User Fields'},...res.payload.fields])
        }
    }
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const getOrganizationGroups = async (page: number, query: string) => {
        setIsloading(true);
        const res: any = await groupService.getOrganizationGroups(id, page, query, 1000);
        setIsloading(false);
        if (res.statusCode == 200) {
            for (const group of res.payload.groups.records) {
                group.childGroups = [];
            }

            setOrganizationGroups([{
                "_id": "-69",
                "name": "Select UserGroup",
                "organization": "6169e2ecdfd86492cd5a1cbf",
                "childGroups": [],
                "creator": "615e155dbbb6280303f1bf38",
                "members": [
                    {
                        "_id": "629d1d97217e486375406ae7",
                        "member": {
                            "_id": "620020738250b70f5c757b65",
                            "name": "Sergino Dest"
                        },
                        "role": "6169e2ecdfd86492cd5a1cc3"
                    }
                ],
                "admins": [
                    {
                        "_id": "6169e2ecdfd86492cd5a1cc6",
                        "member": {
                            "_id": "615e155dbbb6280303f1bf38",
                            "name": "Waleed Ahmad",
                            "avatar": "https://uptod8.s3.amazonaws.com/1344538800-pp.jpeg"
                        },
                        "role": "6169e2ecdfd86492cd5a1cc1"
                    }
                ],
                "startDate": "2022-06-01T19:00:00.000Z",
                "endDate": null,
                "canMemberChat": "YES",
                "createdAt": "2022-06-02T22:05:03.872Z",
                "updatedAt": "2022-06-05T21:18:15.494Z",
            }, ...res.payload.groups.records])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getDashboardVisits = async (page: number, query: string, limit: number, downloadCSV = false, fieldValue:string, fieldSearch: string, groupId: string) => {
        setIsloading(true);
        let userFieldQuery = '';
        if (query){
            userFieldQuery += `&query=${query}`
        }
        if (fieldValue && fieldValue !== '-69'){
            userFieldQuery +=  `&organizationFieldId=${fieldValue}`
            if (fieldSearch){
                userFieldQuery +=  `&fieldValue=${fieldSearch}`
            }
        }
        if (groupId && groupId !== '-69'){
            userFieldQuery +=  `&groupId=${groupId}`
        }
        const res: any = await dashboardService.getDashboardMemberVisitStats(id, page, limit, userFieldQuery);
        setIsloading(false);
        if (res.statusCode == 200) {
            if (downloadCSV) {
                const csvData = [['User', 'Username', 'Email', 'App Activity Count', 'Last Activity At', 'Org Role', 'Org Policies', 'Groups', 'Joining Date', 'Organization Fields','Assignment Key']];
                for (const iterator of res.payload.organizationMembers.records) {
                    const groupNames = iterator.groups.map(function (item: any) {
                        return item['name'];
                    });
                    const fieldValues: any = []
                    iterator.organizationFieldValues.map((item: any) => {
                        fieldValues.push("{"+item.organizationField.label);
                        fieldValues.push(item.value+"}");
                    })
                    let item = [iterator?.member?.name,
                    iterator?.member?.username,
                    iterator?.member?.emailPrivacy === EPrivacy.EVERY_ONE ? iterator?.member?.email : '',
                    iterator?.member?.appActivityCount || 0,
                    moment(iterator?.member?.appActivityAt).format('YYYY-MM-DD hh:mm'),
                    iterator?.role?.role,
                    iterator?.role?.policies?.toString(),
                    groupNames?.toString(),
                    moment(iterator?.createdAt).format('YYYY-MM-DD hh:mm'),
                    fieldValues.toString(),
                    iterator?.comment ?? ''];
                    csvData.push(item);
                }
                setCSVUsers(csvData)
            } else {
                setPaginationInfoViews(res.payload.organizationMembers.paginationInfo);
                if (page == 1) {
                    setMemberVisit([...res.payload.organizationMembers.records])
                } else {
                    setMemberVisit([...memberVisit, ...res.payload.organizationMembers.records])
                }
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const changeHandler = (event: any) => {
        setSelectedGroup(event.target.value);
        getDashboardVisits(1, search, 20, false, fieldSelected.toString(), debounceUserFieldSearch, event.target.value);
    }
    const fieldChangeHandler = (event: any) => {
        setFieldSelected(event.target.value);
        getDashboardVisits(1, search, 20, false , event.target.value.toString(), debounceUserFieldSearch, selectedGroup.toString());
    }
    // const getMembersByGroup = async (groupId: string, page: number, query: string, fieldValue:string, fieldSearch: string) => {
    //     setIsloading(true);
    //     let userFieldQuery = '';
    //     if (fieldValue && fieldValue !== '-69'){
    //         userFieldQuery =  `&organizationFieldId=${fieldValue}`
    //         if (fieldSearch){
    //             userFieldQuery +=  `&fieldValue=${fieldSearch}`
    //         }
    //     }
    //     const res: any = await dashboardService.getDashboardMemberByGroups(groupId, page, query,userFieldQuery);
    //     setIsloading(false);
    //     if (res.statusCode == 200) {
    //         // setPaginationInfoVisits(res.payload.organizationMembers.paginationInfo);
    //         if (page == 1) {
    //             setMemberVisit([...res.payload.topOrganizationMembers.records])
    //         } else {
    //             setMemberVisit([...memberVisit, res.payload.topOrganizationMembers.records])
    //         }
    //     } else {
    //         Toast.fire({
    //             icon: 'warning',
    //             title: res.message
    //         })
    //     }
    // }
    const downloadCSV = () => {
        if (search.length === 0) {
            getDashboardVisits(1, search, paginationInfoViews?.totalRecords!, true, fieldSelected.toString(), debounceUserFieldSearch, selectedGroup.toString());
        } else {
            const csvData = [['User', 'Username', 'Email', 'App Activity Count', 'Last Activity At', 'Org Role', 'Org Policies', 'Groups', 'Joining Date', 'Organization Fields','Assignment Key']];
            for (const iterator of memberVisit) {
                const groupNames = iterator.groups.map(function (item: any) {
                    return item['name'];
                });
                const fieldValues: any = []
                iterator.organizationFieldValues.map((item: any) => {
                    fieldValues.push("{"+item.organizationField.label);
                    fieldValues.push(item.value+"}");
                })
                let item = [iterator?.member?.name,
                iterator?.member?.username,
                iterator?.member?.emailPrivacy === EPrivacy.EVERY_ONE ? iterator?.member?.email : '',
                iterator?.member?.appActivityCount || 0,
                moment(iterator?.member?.appActivityAt).format('YYYY-MM-DD hh:mm'),
                iterator?.role?.role,
                iterator?.role?.policies?.toString(),
                groupNames?.toString(),
                moment(iterator?.createdAt).format('YYYY-MM-DD hh:mm'),
                fieldValues.toString(),
                iterator?.comment?? ''];
                csvData.push(item);
            }
            setCurrentDataCSV(csvData)
        }
    }
    const openProfile = (member: any) => {
        if (currentUser._id === member.member._id) {
            history.push(`/profile-page`)
        } else {
            history.push({
                pathname: `/memberprofilepage/${member.member._id}`,
                search: `${id}&${member._id}`
            });
        }
    }
    const removeMemberFromOrganization = async (item: IOrganizationRequestedMembers, index: number) => {
        Swal.fire({
            title: item.member.name + " (" + item.member.username + ")",
            text: t('areYouSurePublicMember'),
            imageUrl: item?.member?.avatar ?? images.UserPlaceholder,
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'User Image',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('yes'),
            cancelButtonText: t('no'),
            customClass: {
                image: 'rounded-image'
            }
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    try {
                        setIsloading(true)
                        const res: any = await organisationService.makeOrganizationUserPublic(item._id);
                        setIsloading(false)
                        if (res.statusCode == 200) {
                            const tempObj = memberVisit[index];
                            tempObj.role = res.payload.organizationMember.role
                            memberVisit[index] = { ...tempObj};
                            setMemberVisit([...memberVisit!])
                        } else if (res.statusCode == 400){
                            Toast.fire({
                                icon: 'warning',
                                title: t('publicMemberError') + res.payload.groups.map((item: any) => (" " + item.name)).toString()
                            })
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: res.message
                            })
                        }
                    } catch (e: any) {
                        setIsloading(false)
                        Toast.fire({
                            icon: 'warning',
                            title: e?.response?.data?.message
                        })
                    }
                } else {
                    Swal.fire({
                        title: t('removeMember') + item.member.name + " (" + item.member.username + ")",
                        text: t('areYouSureMember'),
                        imageUrl: item?.member?.avatar ?? images.UserPlaceholder,
                        imageWidth: 100,
                        imageHeight: 100,
                        imageAlt: 'User Image',
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('no'),
                        customClass: {
                            image: 'rounded-image'
                        }
                    }).then(async (result: any) => {
                        if (result) {
                            if (result.isConfirmed) {
                                try {
                                    setIsloading(true)
                                    const res: any = await organisationService.removeOrganizationMembers(item._id);
                                    setIsloading(false)
                                    if (res.statusCode == 200) {
                                        memberVisit!.splice(index, 1);
                                        setMemberVisit([...memberVisit!])
                                    } else if (res.statusCode == 400){
                                        Toast.fire({
                                            icon: 'warning',
                                            title: t('publicMemberError') + res.payload.groups.map((item: any) => (" " + item.name)).toString()
                                        })
                                    } else{
                                        Toast.fire({
                                            icon: 'warning',
                                            title: res.message
                                        })
                                    }
                                } catch (e: any) {
                                    setIsloading(false)
                                    Toast.fire({
                                        icon: 'warning',
                                        title: e?.response?.data?.message
                                    })
                                }
                            }
                        }
                    });
                }
            }
        });
    }

    const openMemberRoleChangeModal = (member: any) => {
        setMemberSelectedForRoleChange(member)
        setShowMemberRoleChangeModal(true)
    };
    const closeMemberRoleChangeModal = (data: boolean) => {
        setShowMemberRoleChangeModal(false)
        setMemberSelectedForRoleChange(undefined)
    }
    const updateMemberRole = (updatedRole: any, shouldClose?: boolean) => {
        if (!shouldClose) {
            setShowMemberRoleChangeModal(false);
            setMemberSelectedForRoleChange(undefined)
        }
        let index = memberVisit!.findIndex(rol => rol._id === memberSelectedForRoleChange._id);
        memberVisit[index].role._id = updatedRole._id
        memberVisit[index].role.role = updatedRole.role
        memberVisit[index].role.policies = updatedRole.policies
        setMemberVisit([...memberVisit!])
    }

    const updateValidity = (member: any) => {
        setMemberSelectedForRoleChange(member)
        setOpenValidTillModal(true)
    };

    const updateMemberValidity = (data?: string) => {
        setOpenValidTillModal(false);
        const index = memberVisit.findIndex(item => item._id == memberSelectedForRoleChange._id)
        memberVisit[index].validTill = moment(data)
        setMemberVisit([...memberVisit])
    }
    const validTillCloseModal = (data?: string) => {
        setOpenValidTillModal(false);
        // updateRequestedFollowStatus(userSelectedForAction.status, userSelectedForAction.id, userSelectedForAction.index,'' ,data ? moment(data).format('YYYY-MM-DD hh:mm'): '')
    }
    const closeFieldsModal = () => {
       setShowFieldsModal(false);
       getDashboardVisits(1, search, 20, false , fieldSelected.toString(), debounceUserFieldSearch,selectedGroup.toString());
    }
    const closeGroupModal = () => {
        setShowGroupModal(false);
    }

    const mapUserFields = (fields: any[], fieldId: string ) =>{
        if (fields?.length == 0){
            return '-';
        }
        const found = fields?.find((field) => field.organizationField._id == fieldId)?.value;
        if (found){
            return found
        }else{
            return '-';
        }
    }
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            {isLoading && <FullPageLoader />}
            <div className="up-usersholer">
                <div className="up-userfilterholder">
                    <div className="up-userspagetitle">
                        <h2>{t('userData')}</h2>
                    </div>
                    <form className="up-formtheme">
                        <fieldset>
                            <CSVLink
                                data={csvUsers}
                                filename='users.csv'
                                className='hidden'
                                ref={csvLink}
                                target='_blank'
                            />
                            <CSVLink
                                data={currentDataCSV}
                                filename='users.csv'
                                className='hidden'
                                ref={currentDataLink}
                                target='_blank'
                            />
                            <button type='button' onClick={downloadCSV} className="up-btn">{t('downloadCSV')}</button>
                            <div className="up-select">
                                <select className="up-selecttheme" onChange={(e) => fieldChangeHandler(e)}>
                                    {organizationFields && organizationFields.map((group, index) => {
                                        return <option key={index} value={group._id}>{group.label}</option>;
                                    })}
                                </select>
                            </div>
                            {fieldSelected && fieldSelected !== -69 && <div className="form-group">
                                <div className="up-inputwithicon">
                                    <input type="text" value={userFieldSearch} onChange={(e) => setUserFieldSearch(e.target.value)}
                                           name="search" placeholder={t('searchUserFields')}/>
                                    <i className="icon-search"></i>
                                </div>
                            </div>}
                            <div className="up-select">
                                <select className="up-selecttheme" onChange={(e) => changeHandler(e)}>
                                    {organizationGroups && organizationGroups.map((group, index) => {
                                        return <option key={index} value={group._id}>{group.name}</option>;
                                    })}
                                </select>
                            </div>
                            <div className="form-group">
                                <div className="up-inputwithicon">
                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} name="search" placeholder={t('searchUsers')} />
                                    <i className="icon-search"></i>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="up-userstable">
                    <div className="up-usertableholder">
                        {memberVisit && memberVisit.length > 0 && (
                            <InfiniteScroll
                                dataLength={memberVisit!.length} //This is important field to render the next data
                                next={() => getDashboardVisits(paginationInfoViews?.currentPage! + 1, debounceSearch, 20, false, fieldSelected.toString(), debounceUserFieldSearch, selectedGroup.toString())}
                                hasMore={memberVisit!.length !== paginationInfoViews?.totalRecords!}
                                loader={<h4>{t('loading')}</h4>}
                                endMessage={
                                    <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                        <b>{t('noMoreUsers')}</b>
                                    </p>
                                }>
                                    <div className="up-usertablescrollholder">
                                        <table className="up-tabletheme">
                                            <thead>
                                                <tr>
                                                    <th>{t('name')}</th>
                                                    <th>{t('role')}</th>
                                                    <th>{t('visit')}</th>
                                                    <th>{t('lastVisit')}</th>
                                                    {organizationFields.map((field) => (
                                                        <th>{field.label}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {memberVisit.map((user, index) => (<tr>
                                                    <td>
                                                        <div className="up-usertabledetail" onClick={() => openProfile(user)}>
                                                            <figure>
                                                                <img style={{ cursor: 'pointer' }} src={user?.member?.avatar || Image} alt="user image" />
                                                            </figure>
                                                            <h4 style={{ cursor: 'pointer' }} className="up-tableusername">{user?.member?.name}</h4>
                                                            <span className="up-usertableiconsholser" style={{paddingLeft: 16, display: 'flex', flexDirection:'row'}}>
                                                                <button type="button" title={t('removeUser')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={(e ) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    removeMemberFromOrganization(user, index)
                                                                }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                                                                </button>
                                                                <button type="button" title={t('changeRole')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    openMemberRoleChangeModal(user)
                                                                }}>
                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.86 181">
                                                                        {/* <defs>
                                        <style>
                                        .cls-1 {
                                            fill: '#fff'
                                        }

                                        .cls-2 {
                                            fill: none;
                                        }
                                        </style>
                                    </defs> */}
                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M138.4,123.84v6.48h-21.08v-6.48c0-5.82,4.72-10.54,10.54-10.54,2.91,0,5.55,1.18,7.45,3.09,1.91,1.9,3.09,4.54,3.09,7.45Z"/>
                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M127.86,94.94c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.26,43-43-19.25-43-43-43ZM149.71,161.32c0,3.16-2.56,5.72-5.71,5.72h-32.28c-3.15,0-5.71-2.56-5.71-5.72v-25.28c0-3.16,2.56-5.72,5.71-5.72h.94v-6.29c0-8.39,6.81-15.19,15.2-15.19,4.2,0,7.99,1.7,10.75,4.45,2.74,2.75,4.45,6.55,4.45,10.74v6.29h.94c3.15,0,5.71,2.56,5.71,5.72v25.28Z"/>
                                                                            <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                                                            <g>
                                                                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                                <button type="button" title={t('validity')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    updateValidity(user)
                                                                }}>
                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.77 181">
                                                                        {/* <defs>
                                            <style>
                                            .cls-1 {
                                                fill: '#fff'
                                            }

                                            .cls-2 {
                                                fill: 'none'
                                            }
                                            </style>
                                        </defs> */}
                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M128.77,94.59c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.25,43-43-19.25-43-43-43ZM153.24,159.53c0,3.07-2.49,5.56-5.56,5.56h-37.82c-3.07,0-5.56-2.49-5.56-5.56v-37.82c0-3.07,2.49-5.56,5.56-5.56h6.37c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.79-2.8.78,0,1.48.31,1.98.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h14.06c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.8-2.8.77,0,1.47.31,1.97.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h6.37c3.07,0,5.56,2.49,5.56,5.56v37.82Z"/>
                                                                            <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                                                            <path className="cls-2" style={{fill: 'none'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                                <button type="button" title={t('assignUserFields')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setMemberSelectedForRoleChange(user)
                                                                    setShowFieldsModal(true)
                                                                }}>
                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.59 110.67">
                                                                                                <defs>
                                                                                                    {/* <style>
                                            .cls-1 {
                                            fill: '#fff';
                                            {"}"}
                                        </style> */}
                                                                                                </defs>
                                                                                                <g id="Laag_1-2" data-name="Laag_1">
                                                                                                    <g>
                                                                                                        <circle className="cls-1" style={{fill: '#fff'}} cx="148.52" cy="26.15" r="26.15"/>
                                                                                                        <path className="cls-1" style={{fill: '#fff'}} d="M187.43,110.67h-77.82c-8.19,0-14.04-7.94-11.61-15.76,6.71-21.52,26.79-37.15,50.52-37.15s43.81,15.62,50.52,37.15c2.44,7.82-3.42,15.76-11.61,15.76Z"/>
                                                                                                        <rect className="cls-1" style={{fill: '#fff'}} x=".11" y="15.81" width="101.32" height="17.74" rx="8.87" ry="8.87"/>
                                                                                                        <rect className="cls-1" style={{fill: '#fff'}} x="0" y="75.4" width="67.55" height="17.74" rx="8.87" ry="8.87"/>
                                                                                                        <rect className="cls-1" style={{fill: '#fff'}} x=".44" y="46.22" width="101" height="17.74" rx="8.87" ry="8.87"/>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                </button>
                                                                <button type="button" title={t('assignUserGroups')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setMemberSelectedForRoleChange(user)
                                                                    setShowGroupModal(true)
                                                                }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM609.3 512l-137.8 0c5.4-9.4 8.6-20.3 8.6-32l0-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2l61.4 0C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/></svg>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>{user?.role?.role}</td>
                                                    <td>{user?.member?.appActivityCount || 0}</td>
                                                    <td>{moment(user?.member?.appActivityAt).format('YYYY-MM-DD HH:mm')}</td>
                                                    {organizationFields.map((field) => (
                                                        <td>{mapUserFields(user.organizationFieldValues, field._id)}</td>
                                                    ))}
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                            </InfiniteScroll>
                        )}
                        {memberVisit && memberVisit.length === 0 && (
                            <NoDataFound />
                        )}

                    </div>
                    {/* <div className="up-paginationholder">
                        <ul className="up-pagination">
                            <li>
                                <p>1-8 of 24</p>
                            </li>
                            <li>
                                <button className="up-btn up-btnpag">
                                    <i className="icon-arrow-left2 "></i>
                                </button>
                            </li>
                            <li>
                                <button className="up-btn up-btnpag">
                                    <i className="icon-arrow-right2"></i>
                                </button>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            {showMemberRoleChangeModal && <MemberRoleChangeModal closeModal={closeMemberRoleChangeModal} organizationRoles={organizationRoles} selectedMember={memberSelectedForRoleChange!} updateMemberRole={updateMemberRole} />}
            {openValidTillModal && <ValidTillModal updateMemberValidity={updateMemberValidity} member={memberSelectedForRoleChange} closeModal={validTillCloseModal} />}
            {showFieldsModal && <AssignUserFieldsModal orgComment={''} memberName={memberSelectedForRoleChange?.member?.name} memberId={memberSelectedForRoleChange._id} organizationId={id} closeModal={closeFieldsModal} />}
            {showGroupModal && <UpdateUserGroupModal memberName={memberSelectedForRoleChange?.member?.name} memberId={memberSelectedForRoleChange._id} organizationId={id} closeModal={closeGroupModal} />}
        </DashboardLayout>
    )
}