// SecurityScript.tsx
import { useEffect, FC, useCallback } from 'react';

interface SecurityConfig {
  enableConsoleWarning: boolean;
  disableConsole: boolean;
  preventDevTools: boolean;
  preventRightClick: boolean;
  preventKeyboardShortcuts: boolean;
  preventCopying: boolean;
  enableSourceProtection: boolean;
  enableStorageProtection: boolean;
}

const defaultConfig: SecurityConfig = {
  enableConsoleWarning: true,
  disableConsole: true,
  preventDevTools: true,
  preventRightClick: true,
  preventKeyboardShortcuts: true,
  preventCopying: true,
  enableSourceProtection: true,
  enableStorageProtection: true,
};

interface SecurityScriptProps {
  config?: Partial<SecurityConfig>;
}

const SecurityScript: FC<SecurityScriptProps> = ({ config = defaultConfig }) => {
  // if(process.env.REACT_APP_ENV === 'qa'){
  //   return null;
  // }
  const DEVTOOLS_THRESHOLD = 160;
  const CHECK_INTERVAL = 1000;

  const handleDevToolsDetection = useCallback(() => {
    try {
      const widthThreshold = window.outerWidth - window.innerWidth > DEVTOOLS_THRESHOLD;
      const heightThreshold = window.outerHeight - window.innerHeight > DEVTOOLS_THRESHOLD;

      if (widthThreshold || heightThreshold) {
        // Clear sensitive data and reload
        localStorage.clear();
        sessionStorage.clear();
        document.documentElement.innerHTML = '';
        window.location.reload();
      }
    } catch (error) {
      // Silent fail
    }
  }, []);

  const handleKeyboardShortcuts = useCallback((e: KeyboardEvent) => {
    const blockedKeys = [
      e.key === 'F12',
      e.ctrlKey && e.shiftKey && ['I', 'J', 'C'].includes(e.key.toUpperCase()),
      e.ctrlKey && e.key.toLowerCase() === 'u',
      e.ctrlKey && e.key === 's',
    ];

    if (blockedKeys.some(Boolean)) {
      e.preventDefault();
      return false;
    }
  }, []);
  const preventDefaultHandler = (e: Event) => e.preventDefault();
  useEffect(() => {
    // Only apply in production/staging environment
    if (process.env.REACT_APP_ENV !== 'staging' && process.env.NODE_ENV !== 'production') {
      return;
    }

    const mergedConfig = { ...defaultConfig, ...config };
    let devToolsInterval: NodeJS.Timeout;
    // Basic event prevention handler
    
    try {
      

      // Right-click prevention
      if (mergedConfig.preventRightClick) {
        document.addEventListener('contextmenu', preventDefaultHandler);
      }

      // Keyboard shortcuts prevention
      if (mergedConfig.preventKeyboardShortcuts) {
        document.addEventListener('keydown', handleKeyboardShortcuts);
      }

      // Copy/paste prevention
      if (mergedConfig.preventCopying) {
        ['copy', 'cut', 'paste'].forEach(event => {
          document.addEventListener(event, preventDefaultHandler);
        });
      }

      // DevTools detection
      if (mergedConfig.preventDevTools) {
        window.addEventListener('resize', handleDevToolsDetection);
        devToolsInterval = setInterval(handleDevToolsDetection, CHECK_INTERVAL);
      }

      // Console protection
      if (mergedConfig.disableConsole) {
        const noop = () => undefined;
        const consoleMethods = ['log', 'debug', 'info', 'warn', 'error', 'table'];

        consoleMethods.forEach(method => {
          try {
            (console as any)[method] = noop;
          } catch (e) {
            // Silent fail
          }
        });
      }

      // Warning message
      if (mergedConfig.enableConsoleWarning) {
        try {
          console.log(
            '%cWarning!',
            'color: red; font-size: 30px; font-weight: bold; -webkit-text-stroke: 1px black;'
          );
          console.log(
            '%cThis is a secured application. Any attempt to debug or manipulate may result in account suspension.',
            'font-size: 16px; color: #444;'
          );
        } catch (e) {
          // Silent fail
        }
      }

      // Source protection (without blocking page load)
      if (mergedConfig.enableSourceProtection) {
        try {
          const protection = `
            (function() {
              try {
                if (window.devtools.isOpen) {
                  window.location.reload();
                }
              } catch(e) {}
            })();
          `;
          
          const script = document.createElement('script');
          script.textContent = protection;
          document.head.appendChild(script);
        } catch (e) {
          // Silent fail
        }
      }

      // Storage protection
      if (mergedConfig.enableStorageProtection) {
        try {
          const originalSetItem = localStorage.setItem;
          localStorage.setItem = function(key: string, value: string) {
            const event = new Event('storageCheck');
            document.dispatchEvent(event);
            originalSetItem.apply(this, [key, value]);
          };
        } catch (e) {
          // Silent fail
        }
      }

    } catch (error) {
      // Silent fail - don't block page load
    }

    // Cleanup function
    return () => {
      clearInterval(devToolsInterval);
      window.removeEventListener('resize', handleDevToolsDetection);
      document.removeEventListener('keydown', handleKeyboardShortcuts);
      document.removeEventListener('contextmenu', preventDefaultHandler);
      ['copy', 'cut', 'paste'].forEach(event => {
        document.removeEventListener(event, preventDefaultHandler);
      });
    };
  }, [config, handleDevToolsDetection, handleKeyboardShortcuts]);

  return null;
};

export default SecurityScript;