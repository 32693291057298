import {yupResolver} from "@hookform/resolvers/yup";
import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import AsyncSelect from "react-select/async";
import {images} from "../../../assets/images";
import {useDebounce} from "../../../hooks/use-debounce.hook";
import {IIdFromUrl} from "../../../interfaces/id-from-url.interface";
import {organisationService} from "../../../services/organisation.service";
import {postService} from "../../../services/post.service";
import {Toast} from "../../../utils/Toast";
import {
    IAddParentOrganization
} from "../../organisations/createorganisation/interfaces/add-parent-organization.interface";
import {IOrganisationTypes} from "../../organisations/createorganisation/interfaces/organisation-types.interface";
import {
    IOrganization,
    IOrganizationProps
} from "../../organisations/createorganisation/interfaces/organisation.interface";
import {
    IOrganizationRequestedMembers,
    IOrganizationRole
} from "../../organisations/createorganisation/interfaces/organization-requested-members.interface";
import {
    addParentOrganisationSchema
} from "../../organisations/createorganisation/validations/add-parent-organization.validation";
import {SearchParentOrganisation} from "../../organisations/slice/organisation.thunks";
import {IPost} from "../../organisations/createorganisation/interfaces/post.interface";
import {OrganizationPost} from '../../../components/Post/Post'
import {getUser} from "../../auth/slice/user.selector";
import Swal from "sweetalert2";
import {IPaginationInfo} from "../../../interfaces/pagination-info.interface";
import InfiniteScroll from 'react-infinite-scroll-component';
import {IGroup} from "../../organisations/createorganisation/interfaces/group.interface";
import {groupService} from "../../../services/group.service";
import {EventsTab} from './EventsTab'
import {DisplayToModal} from "./DisplayToModal";
import {SharePostOrganizationModal} from "./SharePostModal";
import {CreatePostModall} from './CreatePostModal'
import {ReportPostModal} from "./ReportPostModal";
import {EChatType} from "../../chatmessages/enum/type.enum";
import {SettingsTab} from "./SettingsTabs";
import {EditOrganization} from "./EditOrganization";
import {MemberRoleChangeModal} from './MemberRoleChangeModal';
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {EPolicy} from "../../../interfaces/organization-policies.enums";
import {InviteMembersModal} from "./InviteMemberModal";
import {SendFollowRequestModal} from "../../auth/pages/follow-organisations/FollowRequestModal";
import {SharePostInUserGroupModal} from "./SharePostInUserGroupsModal";
import {ImportCodeCSVModal} from "./ImportCodeCSVModal";
import {KeyLogModal} from "./KeyLogModal";
import moment from "moment";
import {DeleteCSVModal} from "./DeleteCSVModal";
import {IUserField} from "../../../interfaces/user-fields.interface";
import {UserRoleGroupFieldModal} from "./User-Role-Group-Field-Modal";
import {URLReplacer} from "../../../utils/url-replacer";
import {CreateGroupModal} from "./CreateGroupModal";
import {RequestRejectionModal} from "./RequestRejectionModal";
import {ValidTillModal} from "./ValidTillModal";
import {chatService} from "../../../services/chat.service";
import {ScheduledPostsTab} from "./ScheduledPostTab";
import {RequestedPostsTab} from "./RequestedPostTab";
import {CreateScheduledPostModal} from "./CreateScheduledPostModal";
import {useTranslation} from "react-i18next";
import {requestedPostService} from "../../../services/requested-post.service";
import {UpdateUserGroupModal} from "../../profile/components/UpdateUserGroupModal";
import {AssignUserFieldsModal} from "../../profile/components/AssignUserFieldsModal";

export const OwnOrganisationProfileTabs: React.FC<IOrganizationProps> = (props) => {
    const [createScheduledPostModal, setCreateScheduledPostModal] = useState(false);
    const [createPostModal, setCreatePostModal] = useState(false);
    const [displayToModal, setDisplayToModal] = useState(false);
    const [showthree, setShowthree] = useState(false);
    const [showfour, setShowfour] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [createGroupModal, setCreateGroupModal] = useState(false);
    const [sharePostModal, setSharePostModal] = useState(false);
    const [sharePostInUserGroupsModal, setSharePostInUserGroupsModal] = useState(false);
    const [showReportPostModal, setShowReportPostModal] = useState(false)
    const [requestedMembersToggle, setRequestedMembersToggle] = useState(true);
    const [showImportCsvModal, setShowImportCsvModal] = useState(false);
    const [showImportedCodes, setShowImportedCodes] = useState(false);
    const [requestedChildOrganizations, setRequestedChildOrganizations] = useState<IOrganisationTypes[]>();
    const [requestedMembers, setRequestedMembers] = useState<IOrganizationRequestedMembers[]>();
    const [requestedMembersPaginationInfo, setRequestedMembersPaginationInfo] = useState<IPaginationInfo>();
    const [organizationMembers, setOrganizationMembers] = useState<IOrganizationRequestedMembers[]>([]);
    const [allMembers, setAllMembers] = useState<IOrganizationRequestedMembers[]>();
    const [allParentOrganizations, setAllParentOrganizations] = useState<any[]>([]);
    const [organizationMembersPaginationInfo, setOrganizationMembersPaginationInfo] = useState<IPaginationInfo>();
    const [requestedPaginationInfo, setRequestedPaginationInfo] = useState<IPaginationInfo>();
    const [organizationAdmins, setOrganizationAdmins] = useState<IOrganizationRequestedMembers[]>([]);
    const [organizationPosts, setOrganizationPosts] = useState<IPost[]>([]);
    const [paginationInfoPosts, setPaginationInfoPosts] = useState<IPaginationInfo>();
    const [memberCount, setMemberCount] = useState(0);
    const [selectedOption, setSelectedOption] = useState();
    const [search, setSearch] = useState("");
    const [debounceSearch] = useDebounce(search, 300);
    const [groupSearch] = useDebounce(search, 300);
    const [selectedPostImageVideo, setSelectedPostImageVideo] = useState<any>()
    const [selectedPostFile, setSelectedPostFile] = useState<any>()
    const [isPublicChecked, setIsPublicChecked] = useState(true)
    const [selectedRoles, setSelectedRoles] = useState<string[]>()
    const [fullLoader, setFullLoader] = useState(false);
    const [showUserFields, setShowUserFields] = useState(false);
    const [isEditPost, setIsEditPost] = useState(false);
    const [selectedEditPost, setSelectedEditPost] = useState<IPost>();
    const [postToBeSharedInOrganization, setPostToBeSharedInOrganization] = useState<IPost>();
    const [userOrganizations, setUserOrganization] = useState<IOrganization[]>();
    const [organizationGroups, setOrganizationGroups] = useState<IGroup[]>();
    const [userFields, setUserFields] = useState<IUserField[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<IGroup>();
    const [userGroups, setUserGroups] = useState<IGroup[]>([]);
    const [isEditGroup, setIsEditGroup] = useState(false);
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [selectedComment, setSelectedComment] = useState<string>('');
    const [showMemberRoleChangeModal, setShowMemberRoleChangeModal] = useState(false);
    const [userRoleGroupFieldModal, setUserRoleGroupFieldModal] = useState(false);
    const [editOrganization, setEditOrganization] = useState(false);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState<string[]>()
    const [memberSelectedForRoleChange, setMemberSelectedForRoleChange] = useState<any>()
    const [organizationMedia, setOrganizationMedia] = useState<any[]>([])
    const [organizationMediaPagination, setOrganizationMediaPagination] = useState<IPaginationInfo>()
    const [organizationFiles, setOrganizationFiles] = useState<any[]>([])
    const [organizationFilesPagination, setOrganizationFilesPagination] = useState<IPaginationInfo>()
    const [currentTab, setCurrentTab] = useState<string>(props.currentTab)
    const [breadCrumb, setBreadCrumb] = useState<any[]>([])
    const [importedCodes, setImportedCodes] = useState<any[]>([])
    const [importedCodesPagination, setImportedCodesPagination] = useState<IPaginationInfo>()
    const [codeLogModal, setCodeLogModal] = useState<boolean>(false)
    const [deleteCsvModal, setDeleteCsvModal] = useState<boolean>(false)
    const [selectedCode, setSelectedCode] = useState<string>('');
    const [selectedMemberTab, setSelectedMemberTab] = useState<string>('Admin');
    const [openRejectionModal, setOpenRejectionModal] = useState<boolean>(false)
    const [openValidTillModal, setOpenValidTillModal] = useState<boolean>(false)
    const [userSelectedForAction, setUserSelectedForAction] = useState<any>()
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [showFieldsModal, setShowFieldsModal] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'OwnOrganisationProfileTabs' });

    let { id } = useParams<IIdFromUrl>();
    const dispatch = useDispatch();
    const currentUser = useSelector(getUser);
    const history = useHistory();

    useEffect(() => {
        searchMembers(debounceSearch);
    }, [debounceSearch]);

    useEffect(() => {
        // setIsloading(true)
        if (groupSearch.length > 0) {
            getOrganizationGroups(1, groupSearch);
        }
    }, [groupSearch]);
    useEffect(() => {
        switch (currentTab) {
            case 'timeline':
                getOrganizationPosts(1)
                if (props.myRole.role.role == 'Admin') {
                    getRequestedMembers();
                    getRequestedPosts(1)
                }
                break;
            case 'about':
                break;
            case 'members':
                setRequestedMembersToggle(true);
                setFullLoader(true);
                setTimeout(() => {
                    getRequestedOrganizations();
                    getRequestedMembers();
                    getOrganizationMembers(1);
                }, 250)
                break;
            case 'groups':
                if (props.myRole.role.policies.includes(EPolicy.CAN_VIEW_GROUPS)) {
                    getOrganizationMembers(1);
                    setOrganizationGroups(undefined);
                    getOrganizationGroups(1, groupSearch);
                    getParentOrganizations(' ')
                }
                break;
            case 'media':
                if (props.myRole.role.policies.includes(EPolicy.CAN_VIEW_FILES)) {
                    setOrganizationMedia([]);
                    setOrganizationFiles([]);
                    setOrganizationMediaPagination(undefined);
                    setOrganizationFilesPagination(undefined);
                    setFullLoader(true);
                    setTimeout(() => {
                        getOrganizationMedia(1);
                        getOrganizationFiles(1);
                    }, 2000)
                }
                break;
            case 'events':
                break;
            case 'settings':
                break;
        }
    }, [])
    useEffect(() => {
        if (!selectedPostImageVideo) {
            return
        }
    }, [selectedPostImageVideo])
    useEffect(() => {
    }, [selectedPostFile, createPostModal])
    useEffect(() => {
        props.setOrganizationAdmins(organizationAdmins)
    }, [organizationAdmins])
    useEffect(() => {
        setRequestedMembersToggle(true);
        setOrganizationMedia([]);
        setOrganizationFiles([]);
        setOrganizationMediaPagination(undefined);
        setOrganizationFilesPagination(undefined);
        setOrganizationPosts([]);
        setPaginationInfoPosts(undefined);
        setOrganizationGroups(undefined);

    }, [currentTab])

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(addParentOrganisationSchema)
    });

    const handleTabSelection = (tab: string) => {
        setCurrentTab(tab);
        setSearch('');
        switch (tab) {
            case 'timeline':
                if (props.myRole.role.policies.includes(EPolicy.CAN_VIEW_POSTS)) {
                    setOrganizationPosts([]);
                    setPaginationInfoPosts(undefined);
                    getOrganizationPosts(1)
                }
                break;
            case 'about':
                break;
            case 'members':
                setRequestedMembersToggle(true);
                setFullLoader(true);
                setTimeout(() => {
                    getRequestedOrganizations();
                    getRequestedMembers();
                    getOrganizationMembers(1, 'Admin');
                }, 250)

                break;
            case 'groups':
                if (props.myRole.role.policies.includes(EPolicy.CAN_VIEW_GROUPS)) {
                    getOrganizationMembers(1);
                    setBreadCrumb([]);
                    setOrganizationGroups(undefined);
                    getOrganizationGroups(1, '');
                    getParentOrganizations(' ')
                }
                break;
            case 'media':
                if (props.myRole.role.policies.includes(EPolicy.CAN_VIEW_FILES)) {
                    setOrganizationMedia([]);
                    setOrganizationFiles([]);
                    setOrganizationMediaPagination(undefined);
                    setOrganizationFilesPagination(undefined);
                    setFullLoader(true);
                    setTimeout(() => {
                        getOrganizationMedia(1);
                        getOrganizationFiles(1);
                    }, 2000)
                }
                break;
            case 'events':
                break;
            case 'settings':
                break;
        }
    }
    const getOrganizationPosts = async (page: number) => {
        let res: any;
        try {
            if (props.myRole.isMember) {
                res = await postService.getOrganizationsPosts(id, page);
            } else {
                res = await postService.getOrganizationPublicPosts(id, page);
            }
            if (res.statusCode == 200) {
                setPaginationInfoPosts(res.payload.posts.paginationInfo)
                if (page > 1) {
                    setOrganizationPosts([...organizationPosts, ...res.payload.posts.records])
                } else {
                    setOrganizationPosts([...res.payload.posts.records])
                }
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        } catch (e: any) {
            Toast.fire({
                icon: 'warning',
                title: e.response.data.message
            })
        }
    }

    const getRequestedPosts = async (page: number) => {

        const res: any = await requestedPostService.getRequestedPosts(id, page)
        if (res.statusCode == 200) {
            setRequestedPaginationInfo(res.payload.posts.paginationInfo)
        }
    }

    const getOrganizationMembers = async (page: number, type?:string) => {
        setFullLoader(true);
        const res: any = await organisationService.getOrganizationMembers(id, page, search, 25, type);
        if (res.statusCode == 200) {
            const tempAdmins: IOrganizationRequestedMembers[] = [];
            const tempMembers: IOrganizationRequestedMembers[] = [];
            for (const record of res.payload.organizationMembers.records) {
                switch (record.role.role) {
                    case 'Admin':
                        tempAdmins.push(record)
                        break;
                    default:
                        tempMembers.push(record)
                        break;
                }
            }
            setOrganizationMembersPaginationInfo(res.payload.organizationMembers.paginationInfo)
            const dummyMembers: any = [...tempAdmins, ...tempMembers]
            for (const record of dummyMembers) {
                record.label = record.member.name;
                record.value = record.member._id;
                if (record.value == currentUser._id) {
                    record.isAdmin = true;
                    record.added = true;
                } else {
                    record.isAdmin = false;
                    record.added = false;
                }
            }
            setAllMembers([...dummyMembers]);
            switch (type) {
                case 'Admin':
                    if (page == 1)
                    {
                        tempAdmins.length > 0 && setOrganizationAdmins([...tempAdmins])
                    }else{
                        setOrganizationAdmins([...organizationAdmins,...tempAdmins])
                    }
                    break;
                case 'Member':
                    if (page == 1)
                    {
                        tempMembers.length > 0 && setOrganizationMembers([...tempMembers])
                    }else{
                        setOrganizationMembers([...organizationMembers!,...tempMembers])
                    }
                    break;
                default:
                    if (page == 1)
                    {
                        tempAdmins.length > 0 && setOrganizationAdmins([...tempAdmins])
                        tempMembers.length > 0 && setOrganizationMembers([...tempMembers])
                    }else{
                        setOrganizationAdmins([...organizationAdmins,...tempAdmins])
                        setOrganizationMembers([...organizationMembers!,...tempMembers])
                    }
                    break;

            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }
    const getRequestedOrganizations = async () => {
        setFullLoader(true);
        const res: any = await organisationService.getRequestedOrganizations(id);
        if (res.statusCode == 200) {
            setRequestedChildOrganizations([...res.payload.organizations])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }
    const getRequestedMembers = async () => {
        setFullLoader(true);
        const res: any = await organisationService.getOrganizationRequestedMembers(id, 1);
        if (res.statusCode == 200) {
            setRequestedMembers([...res.payload.organizationMembers.records])
            setRequestedMembersPaginationInfo(res.payload.organizationMembers.paginationInfo)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }
    const parentOrganizationStatusChange = async (childId: string, requestStatus: string, index: number) => {
        const data = {
            parentOrganizationId: id,
            childOrganizationId: childId,
            status: requestStatus
        }
        const res: any = await organisationService.childOrganizationStatusUpdateRequest(data);
        if (res.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.message
            })
            requestedChildOrganizations!.splice(index, 1)
            setRequestedChildOrganizations([...requestedChildOrganizations!])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const rejectionCloseModal = (data?: string) => {
        setOpenRejectionModal(false);
        updateRequestedFollowStatus('REJECTED', userSelectedForAction.id, userSelectedForAction.index, data?.trim())
    }
    const validTillCloseModal = (data?: string) => {
        setOpenValidTillModal(false);
        updateRequestedFollowStatus(userSelectedForAction.status, userSelectedForAction.id, userSelectedForAction.index,'' ,data ? moment(data).format('YYYY-MM-DD hh:mm'): '')
    }
    const updateMemberValidity = (data?: string) => {
        setOpenValidTillModal(false);
        const index = organizationMembers.findIndex(item => item._id == memberSelectedForRoleChange._id)
        organizationMembers[index].validTill = moment(data)
        setOrganizationMembers([...organizationMembers])
        // setMemberSelectedForRoleChange(null)
    }
    const updateRequestedFollowStatus = async (status: string, id: string, index: number, reason?: string, validTill?: string) => {
        if (status === 'OPEN_MODAL') {
            setUserSelectedForAction({
                status: 'REJECTED', id, index
            })
            setOpenRejectionModal(true);
            return
        }
        const data: any = {
            status: status,
        }
        if (reason) {
            data.reason = reason;
        }
        if (validTill) {
            data.validTill = validTill;
        }
        const res: any = await organisationService.updateOrganizationFollowStatus(data, id);
        if (res.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.message
            })
            const temp: any = requestedMembers!.splice(index, 1)
            if (status === 'ACCEPTED') {
                const members: any = [];
                temp[0].status = "ACCEPTED";
                members.push(temp[0]);
                setMemberCount(memberCount + 1)
                if (organizationMembers && organizationMembers!.length > 0) {
                    for (const iterator of organizationMembers!) {
                        members.push(iterator)
                    }
                }
                setMemberSelectedForRoleChange(temp[0])
                setUserRoleGroupFieldModal(true)
                setOrganizationMembers([...members])
            }
            if (requestedMembers!.length == 0 )
            {
                setRequestedMembersToggle(!requestedMembersToggle)
            }
            setRequestedMembers([...requestedMembers!])
            const totalRecord = (requestedMembersPaginationInfo?.totalRecords || 1) - 1;
            setRequestedMembersPaginationInfo({
                currentPage: requestedMembersPaginationInfo?.currentPage!,
                pages: requestedMembersPaginationInfo?.pages!,
                perPage: requestedMembersPaginationInfo?.perPage!, totalRecords:totalRecord})
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const openValidModal = async (status: string, id: string, index: number) => {
        setUserSelectedForAction({
            status, id, index
        })
        updateRequestedFollowStatus(status, id, index,'' ,'')
        // setOpenValidTillModal(true);
    }
    const removeMemberFromOrganization = async (item: IOrganizationRequestedMembers, index: number) => {
        Swal.fire({
            title: item.member.name + " (" + item.member.username + ")",
            text: t('areYouSurePublicMember'),
            imageUrl: item?.member?.avatar ?? images.UserPlaceholder,
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'User Image',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('yes'),
            cancelButtonText: t('no'),
            customClass: {
                image: 'rounded-image'
            }
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    try {
                        setFullLoader(true)
                        const res: any = await organisationService.makeOrganizationUserPublic(item._id);
                        setFullLoader(false)
                        if (res.statusCode == 200) {
                            organizationMembers[index] = { ...organizationMembers[index], ...res.payload.organizationMember };
                            setOrganizationMembers([...organizationMembers!])
                        } else if (res.statusCode == 400){
                            Toast.fire({
                                icon: 'warning',
                                title: t('publicMemberError') + res.payload.groups.map((item: any) => (" " + item.name)).toString()
                            })
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: res.message
                            })
                        }
                    } catch (e: any) {
                        setFullLoader(false)
                        Toast.fire({
                            icon: 'warning',
                            title: e?.response?.data?.message
                        })
                    }
                } else {
                    Swal.fire({
                        title: t('removeMember') + item.member.name + " (" + item.member.username + ")",
                        text: t('areYouSureMember'),
                        imageUrl: item?.member?.avatar ?? images.UserPlaceholder,
                        imageWidth: 100,
                        imageHeight: 100,
                        imageAlt: 'User Image',
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('no'),
                        customClass: {
                            image: 'rounded-image'
                        }
                    }).then(async (result: any) => {
                        if (result) {
                            if (result.isConfirmed) {
                                try {
                                    setFullLoader(true)
                                    const res: any = await organisationService.removeOrganizationMembers(item._id);
                                    setFullLoader(false)
                                    if (res.statusCode == 200) {
                                        organizationMembers!.splice(index, 1);
                                        setOrganizationMembers([...organizationMembers!])
                                    } else if (res.statusCode == 400){
                                        Toast.fire({
                                            icon: 'warning',
                                            title: t('publicMemberError') + res.payload.groups.map((item: any) => (" " + item.name)).toString()
                                        })
                                    } else{
                                        Toast.fire({
                                            icon: 'warning',
                                            title: res.message
                                        })
                                    }
                                } catch (e: any) {
                                    setFullLoader(false)
                                    Toast.fire({
                                        icon: 'warning',
                                        title: e?.response?.data?.message
                                    })
                                }
                            }
                        }
                    });
                }
            }
        });
    }
    const onChangeSelectedOption = (e: any) => {
        setSelectedOption(e.value);
    };

    const getOrganizationMedia = async (page: number) => {
        setFullLoader(true);
        const res: any = await organisationService.getOrganizationMedia(id, page, 'IMAGE');
        if (res.statusCode == 200) {
            if (organizationMedia) {
                setOrganizationMedia([...organizationMedia!, ...res.payload.posts.records])
            } else {
                setOrganizationMedia(res.payload.posts.records)
            }
            setOrganizationMediaPagination(res.payload.posts.paginationInfo)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }

    const getOrganizationFiles = async (page: number) => {
        setFullLoader(true);
        const secRes: any = await organisationService.getOrganizationMedia(id, page, 'FILE');
        if (secRes.statusCode == 200) {
            if (organizationFiles) {
                setOrganizationFiles([...organizationFiles!, ...secRes.payload.posts.records])
            } else {
                setOrganizationFiles(secRes.payload.posts.records)
            }
            setOrganizationFilesPagination(secRes.payload.posts.paginationInfo)
        } else {
            Toast.fire({
                icon: 'warning',
                title: secRes.message
            })
        }
        setFullLoader(false);
    }
    const getParentOrganizations = async (inputValue: string) => {
        const res: any = await groupService.getOrganizationGroups(id, 1, inputValue, 1000);
        if (res.statusCode == 200) {
            const tempArray: any[] = [];
            for (let i = 0; i < res.payload.groups.records.length; i++) {
                tempArray.push({
                    label: res.payload.groups.records[i].name,
                    value: res.payload.groups.records[i]._id
                })
            }
            setAllParentOrganizations(tempArray)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const promiseOptions = async (inputValue: string) => {
        if (inputValue.length > 1) {
            const res: any = await dispatch(SearchParentOrganisation(inputValue))
            if (res.payload.statusCode == 200) {
                const tempArray: any[] = [];
                for (let i = 0; i < res.payload.payload.organizations.records.length; i++) {
                    tempArray.push({
                        label: res.payload.payload.organizations.records[i].name,
                        value: res.payload.payload.organizations.records[i]._id
                    })
                }
                return tempArray;
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.payload.message
                })
            }
        }
    }
    const onSubmit = async (data: IAddParentOrganization) => {
        data.childOrganization = id;
        if (selectedOption) {
            data.parentOrganization = selectedOption;
            const res: any = await organisationService.addParentOrganization(data)
            if (res.statusCode == 200) {
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
                handleClosefour()
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.payload.message
                })
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: t('selectParentOrganization')
            })
        }

    };
    const searchMembers = async (input: string) => {
        const res: any = await organisationService.getOrganizationMembers(id, 1, input, 20);
        if (res.statusCode == 200) {
            const tempAdmins: IOrganizationRequestedMembers[] = [];
            const tempMembers: IOrganizationRequestedMembers[] = [];
            setMemberCount(res.payload.organizationMembers.records.length)
            for (const record of res.payload.organizationMembers.records) {
                switch (record.role.role) {
                    case 'Admin':
                        tempAdmins.push(record);
                        break;
                    case 'Follower':
                        tempMembers.push(record);
                        break;
                    default:
                        tempMembers.push(record);
                }
            }
            if (selectedGroupMembers && selectedGroupMembers!.length > 0) {
                for (const iterator of selectedGroupMembers) {
                    const tempIndex = tempMembers.findIndex(item => item._id === iterator);
                    if (tempIndex != -1) {
                        tempMembers[tempIndex].added = true;
                    }
                }
            }
            setOrganizationAdmins([...tempAdmins])
            setOrganizationMembers([...tempMembers])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const handleRoleSelection = (event: any, role: IOrganizationRole, index: number) => {
        role.checked = event.target.checked
        if (event.target.checked) {
            const temp: string[] = []
            temp.push(event.target.id)
            if (selectedRoles) {
                setSelectedRoles([...selectedRoles, ...temp])
            } else {
                setSelectedRoles([...temp])
            }
        } else {
            const temp = selectedRoles!.findIndex((role) => role === event.target.id)
            if (temp != -1) {
                selectedRoles!.splice(temp, 1)
                setSelectedRoles([...selectedRoles!])
            }
        }
    }
    const publicChangeHandler = (event: any) => {
        if (event.target.checked) {
            setSelectedRoles(undefined);
            for (const iterator of props.organizationRoles) {
                iterator.checked = false;
            }
        }
        setIsPublicChecked(!isPublicChecked)
    }

    const sharePostInOrganization = async (selectedPost: IPost) => {
        setPostToBeSharedInOrganization(selectedPost);
        const res: any = await organisationService.getUserOrganizations(currentUser._id)
        if (res.statusCode === 200) {
            const temp = [{ _id: 0, name: 'Select Organisation.' }, ...res.payload.organizations]
            setUserOrganization(temp)
            setSharePostModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const shareInUserGroupsPost = async (selectedPost: IPost) => {
        setPostToBeSharedInOrganization(selectedPost);
        const res: any = await groupService.getUserGroups(1, '', selectedPost.organization?._id)
        if (res.statusCode === 200) {
            const temp = [{ _id: 0, name: 'Select User Group' }, ...res.payload.groups.records]
            setUserGroups(temp)
            setSharePostInUserGroupsModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const editPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setIsEditPost(true);
        setCreatePostModal(true);
    }
    const reportPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setShowReportPostModal(true);
    }
    const editGroup = (group: IGroup, index: number) => {
        setSelectedGroup(group);
        setIsEditGroup(true)
        setCreateGroupModal(true);
    }
    const clearGroupHistory = async (groupID: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await groupService.clearGroupHistory(groupID)
                    if (res.statusCode == 200) {
                        Toast.fire({
                            icon: 'success',
                            title: t('groupHistoryCleared')
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const clearGroupChatHistory = async (groupID: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await groupService.clearGroupChatHistory(groupID)
                    if (res.statusCode == 200) {
                        Toast.fire({
                            icon: 'success',
                            title: t('groupChatHistoryCleared')
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const handleClose = () => {
        setCreatePostModal(false);
        setSelectedRoles(undefined);
        setIsEditPost(false);
        setSelectedEditPost(undefined)
        setCreateScheduledPostModal(false);
    }

    const deletePost = async (selectedPost: IPost) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await postService.deletePost(selectedPost._id);
                    if (res.statusCode = 200) {
                        const tempIndex = organizationPosts!.findIndex((post: any) => post._id === selectedPost._id)
                        organizationPosts!.splice(tempIndex, 1);
                        setOrganizationPosts([...organizationPosts!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }

    const closeDisplayToModal = () => {
        setDisplayToModal(!displayToModal);
        setCreatePostModal(!createPostModal);
    }
    const getOrganizationGroups = async (page: number, query: string) => {
        setFullLoader(true);
        const res: any = await groupService.getOrganizationGroups(id, page, query, 1000);
        if (res.statusCode == 200) {
            // for (const group of res.payload.groups.records) {
            //     group.childGroups = [];
            // }
            setOrganizationGroups([...res.payload.groups.records])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }

    const deleteGroup = async (group: IGroup, index: number) => {
        Swal.fire({
            title: t('deleteGroup'),
            text: t('areYouSureDelete'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel')
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await groupService.deleteGroup(group._id);
                    if (res.statusCode === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: res.message
                        })
                        organizationGroups!.splice(index, 1)
                        setOrganizationGroups([...organizationGroups!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const handleClosethree = () => setShowthree(false);
    const handleShowfour = () => setShowfour(true);
    const handleClosefour = () => setShowfour(false);
    const handleShowfive = () => setShowInviteModal(true);
    const handleClosefive = () => setShowInviteModal(false);
    const closeCSVModal = (data: boolean) => {
        setShowImportCsvModal(false)
        if (data) {
            getImportedCodes();
        }
    };
    const closeCodeLogModal = (data: boolean) => {
        setCodeLogModal(false)
    };
    const closeDeleteCsvModal = (data: boolean) => {
        setDeleteCsvModal(false)
        if (data) {
            window.location.reload();
            // setImportedCodes([]);
            // setFullLoader(true);
            // setTimeout(() => {
            //     getImportedCodes();
            // }, 2000)
        }
    };
    const closeSharePostModal = (data: boolean) => {
        setSharePostModal(false);
        setSharePostInUserGroupsModal(false);
    }
    const openCreateGroupModal = () => {
        setIsEditGroup(false);
        setCreateGroupModal(true);
    }
    const closeGroupCreationModal = (data: boolean) => {
        if (data){
            getAllGroups()
        }
        setCreateGroupModal(false)
    }
    const setPosts = (data: IPost[]) => {
        if (data && data[0]?.isPinned === 'YES')
        {
            setOrganizationPosts([]);
            getOrganizationPosts(1)
        }else{
            setOrganizationPosts([...data]);
        }
        setCreateScheduledPostModal(false);
        setCreatePostModal(false);
    }
    const closeReportPostModal = () => {
        setShowReportPostModal(false);
    }
    const setOrganizationRole = (isCreate: boolean, data: any) => {
        if (data) {
            props.setOrganizationRole(isCreate, data);
        }
    }
    const deleteOrganizationRole = (index: number) => {
        props.deleteOrganizationRole(index)
    }
    const closeMemberRoleChangeModal = (data: boolean) => {
        setShowMemberRoleChangeModal(false)
        setMemberSelectedForRoleChange(undefined)
    }
    const closeRoleGroupFieldChangeModal = (data: boolean) => {
        setUserRoleGroupFieldModal(false)
        setMemberSelectedForRoleChange(undefined)
    }
    const openMemberRoleChangeModal = (member: any) => {
        setMemberSelectedForRoleChange(member)
        setShowMemberRoleChangeModal(true)
    };
    const updateValidity = (member: any) => {
        setMemberSelectedForRoleChange(member)
        setOpenValidTillModal(true)
    };
    const updateMemberRole = (updatedRole: any, shouldClose?: boolean) => {
        if (!shouldClose)
        {
            setShowMemberRoleChangeModal(false);
        }
        let index = organizationMembers!.findIndex(rol => rol._id === memberSelectedForRoleChange._id);
        if (!shouldClose) {
            setMemberSelectedForRoleChange(undefined)
        }
        if (updatedRole.role === 'Admin') {
            const temp: any = organizationMembers?.splice(index, 1)
            temp[0].role._id = updatedRole._id
            temp[0].role.role = updatedRole.role
            temp[0].role.policies = updatedRole.policies
            organizationAdmins!.push(temp[0])
            setOrganizationMembers([...organizationMembers!])
            setOrganizationAdmins([...organizationAdmins!])
        } else if (memberSelectedForRoleChange.role.role === 'Admin') {
            let adminIndex = organizationAdmins!.findIndex(rol => rol._id === memberSelectedForRoleChange._id);
            const temp: any = organizationAdmins?.splice(adminIndex, 1)
            temp[0].role._id = updatedRole._id
            temp[0].role.role = updatedRole.role
            temp[0].role.policies = updatedRole.policies
            organizationMembers!.push(temp[0])
            setOrganizationMembers([...organizationMembers!])
            setOrganizationAdmins([...organizationAdmins!])
        } else {
            if (index !== -1) {
                organizationMembers![index].role._id = updatedRole._id
                organizationMembers![index].role.role = updatedRole.role
                organizationMembers![index].role.policies = updatedRole.policies
                setOrganizationMembers([...organizationMembers!])
            } else {
                index = organizationAdmins!.findIndex(rol => rol._id === memberSelectedForRoleChange._id);
                organizationAdmins![index].role._id = updatedRole._id
                organizationAdmins![index].role.role = updatedRole.role
                organizationAdmins![index].role.policies = updatedRole.policies
                setOrganizationAdmins([...organizationAdmins!])
            }
        }
    }
    const openUserProfile = (member: any) => {
        if (currentUser._id === member.member._id) {
            // history.push(`/profile-page`)
            history.push({
                pathname: `/profile-page`,
                search: `${id}&${member._id}`
            });
        } else {
            if (props.myRole.role.role == 'Admin') {
                history.push({
                    pathname: `/memberprofilepage/${member.member._id}`,
                    search: `${id}&${member._id}`
                });
            } else {
                history.push(`/memberprofilepage/${member.member._id}`);
            }
        }
    }
    const openCommentModals = (item: IOrganizationRequestedMembers, index: number) => {
        setSelectedComment(item!.comment ?? '');
        setOpenCommentModal(true)
    }
    const getChildGroups = async (group: IGroup, index: number) => {
        setFullLoader(true);
        breadCrumb.push({
            groupId: group._id,
            groupName: group.name
        })
        const res: any = await groupService.getChildGroups(group._id);
        if (res.statusCode == 200) {
            setBreadCrumb([...breadCrumb])
            setOrganizationGroups([...res.payload.childGroups])
        }
        setFullLoader(false);
    }
    const getSpecficGroupChild = async (group: any, index: number) => {
        setFullLoader(true);
        breadCrumb.splice(++index, breadCrumb.length)
        const res: any = await groupService.getChildGroups(group.groupId);
        if (res.statusCode == 200) {
            setBreadCrumb([...breadCrumb])
            setOrganizationGroups([...res.payload.childGroups])
        }
        setFullLoader(false);
    }
    const getAllGroups = () => {
        setBreadCrumb([]);
        setOrganizationGroups(undefined);
        getOrganizationGroups(1, '');
    }
    const chatRedirectionHandler =async (groupId: string) => {
        const res = await chatService.getGroupChatDetail(groupId);
        history.push(`/all-chat?tab=${EChatType.GROUP}&active=${res.payload.chat._id}&groupId=${groupId}`) 
    }
    const renderGroups = () => {
        return organizationGroups!.map((group: IGroup, index: number) => {
            return <div key={group._id} className="up-group up-dropdownstyle">
                <div className="up-groupInfoHolder">
                    <div className="up-gourpimagename">
                        <figure className="up-groupimg">
                            <img src={group?.avatar ?? images.RestPlaceholder} alt="Group Image" />
                        </figure>
                        <div className="up-groupname">
                            <h3> <span>{group.name}</span> {group.childGroups.length > 0 && <>
                                <i onClick={() => getChildGroups(group, index)} className="icon-arrow-down2" style={{ marginLeft: '5px', cursor: 'pointer'  }} />
                                <p style={{ cursor: 'pointer' }} onClick={() => getChildGroups(group, index)}>{t('has')} {group.childGroups.length} {t('subGroups')}</p>
                            </>}</h3>
                        </div>
                    </div>
                    <Dropdown className="up-themedropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                            <i className="icon-menu-icon"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="up-themedropdownmenu">
                            {props.myRole.role.role == 'Admin' && <Dropdown.Item onClick={() => clearGroupHistory(group._id)}>{t('clearGroupHistory')}</Dropdown.Item>}
                            {props.myRole.role.role == 'Admin' && <Dropdown.Item onClick={() => clearGroupChatHistory(group._id)}>{t('clearGroupChatHistory')}</Dropdown.Item>}
                            {props.myRole.role.policies.includes(EPolicy.CAN_UPDATE_GROUPS) && <Dropdown.Item onClick={() => editGroup(group, index)}>{t('editGroup')}</Dropdown.Item>}
                            {props.myRole.role.policies.includes(EPolicy.CAN_DELETE_GROUPS) && <Dropdown.Item onClick={() => deleteGroup(group, index)} >{t('delete')}</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                    <button type="button" onClick={() => chatRedirectionHandler(group._id)} className="up-btn up-btnwithicon">
                        <span>{t('openGroupChat')}</span>
                        <i className="icon-chat"></i>
                    </button>
                </div>
            </div>
        })
    }
    const setOrganization = (org: any) => {
        props.setOrganization(org);
        setEditOrganization(false);
    }
    const getImportedCodes = async (page: number = 1) => {
        setFullLoader(true);
        setShowUserFields(false)
        const res: any = await organisationService.getOrganizationInviteCodes(props.organization._id, page);
        if (res.statusCode == 201) {
            setImportedCodes([...importedCodes, ...res.payload.codes.records]);
            setImportedCodesPagination(res.payload.codes.paginationInfo);
            setShowImportedCodes(true);
        }
        setFullLoader(false);
    }
    const hideImportedData = () => {
        setShowUserFields(false)
        setImportedCodes([]);
        setImportedCodesPagination(undefined);
        setShowImportedCodes(false);
    }
    const openCodeLogModal = (item:any , ind: number) => {
        setSelectedCode(item.code);
        setCodeLogModal(true)
    }
    const openDeleteCsvModal = () => {
        setDeleteCsvModal(true)
    }
    const setLikePost = (postId: string, userId: string, status: string) => {

        const postIndex = organizationPosts?.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
            if (status === 'YES') {
                organizationPosts[postIndex].likes.push(currentUser._id);
            } else {
                const index = organizationPosts[postIndex].likes.findIndex((lik) => lik === userId);
                if (index !== -1) {
                    organizationPosts[postIndex].likes.splice(index, 1);
                }
            }
            setOrganizationPosts([...organizationPosts])
        }
    }
    const getUserFields = async () => {
        setFullLoader(true);
        setShowImportedCodes(false);
        const res: any = await organisationService.getOrganizationFields(props.organization._id);
        if (res.statusCode == 200) {
            setUserFields(res.payload.fields)
            setShowUserFields(true)
        }
        setFullLoader(false);
    }
    const addUserFields = () => {
        setUserFields([...userFields,{_id: '', label: ''}])
    }
    const handleFieldChange =(e:any, index: number) => {
        userFields[index].label = e.target.value
        setUserFields([...userFields])
    }
    const deleteUserField = async (item: any, index:number )=> {
       if (item._id)
       {
           Swal.fire({
             title: t('deleteUserField'),
             text: t('areYouSureDelete'),
             icon: "question",
             showCancelButton: true,
             confirmButtonColor: "#3085d6",
             cancelButtonColor: "#d33",
             confirmButtonText: t('accept'),
             cancelButtonText: t('reject'),
           }).then(async (result: any) => {
             if (result) {
               if (result.isConfirmed) {
                 const res:any = await organisationService.deleteOrganizationUserField(item._id)
                   if (res.statusCode == 200) {
                       userFields.splice(index,1)
                       setUserFields([...userFields])
                       Toast.fire({
                           icon: "success",
                           title: res.message,
                       });
                   }
               }
             }
           });
       }else{
           userFields.splice(index,1)
           setUserFields([...userFields])
       }
    }
    const saveUserField = async (item: any, index:number )=> {
        if (item.label.length === 0)
        {
            Toast.fire({
                icon: "warning",
                title: t('cannotSaveEmpty'),
            });
            return
        }
       if (item._id)
       {
           const res: any = await organisationService.updateOrganizationFields(item._id,{
               label: item.label
           })
           if (res.statusCode == 200)
           {
               Toast.fire({
                   icon: "success",
                   title: res.message,
               });
               userFields[index] = res.payload.field;
               setUserFields([...userFields])
           }
       }else{
           const res: any = await organisationService.createOrganizationFields({
               organization:id,
               label: item.label
           })
           if (res.statusCode == 201)
           {
               Toast.fire({
                   icon: "success",
                   title: res.message,
               });
               userFields[index] = res.payload.field;
               setUserFields([...userFields])
           }
       }
    }

    const handleMemberTabSelection = (e: string) => {
        setSelectedMemberTab(e)
        getOrganizationMembers(1,e)
    }

    const openUrl = (item: any) => {
        if (item.slice(0, 3) === 'www') {
            item = 'https://' + item
        }
        window.open(item, "_blank")
    }

    const removeFromList = (id:string) => {
       const index =  organizationPosts.findIndex(item => item._id === id)
        organizationPosts.splice(index,1)
        setOrganizationPosts([...organizationPosts])
    }

    const updateRequestedPostsCounts = () => {
        setRequestedPaginationInfo({
            currentPage: requestedPaginationInfo?.currentPage || 0,
            pages: requestedPaginationInfo?.pages || 0,
            perPage: requestedPaginationInfo?.perPage || 0,
            totalRecords: (requestedPaginationInfo?.totalRecords ? requestedPaginationInfo.totalRecords : 1) - 1
        })
    }
    const closeGroupModal = () => {
        setShowGroupModal(false);
    }
    const closeFieldsModal = () => {
        setShowFieldsModal(false);
    }
    return (
        <>
            <div className="up-organiserprofiletabarea">
                {fullLoader && <FullPageLoader />}
                {/* On click this button toggle remove tabs and on the Edit Organisation show  */}
                {/*{editOrganization && <button onClick={() => setEditOrganization(!editOrganization)} className="up-btn up-btncanceledit" type="button">*/}
                {/*    <span>Cancel</span>*/}
                {/*    /!* <i className="icon-pencil"></i> *!/*/}
                {/*</button>}*/}
                {props.myRole.isMember && props.myRole.role.policies.includes(EPolicy.CAN_UPDATE_ORGANIZATION_SETTINGS) && <button onClick={() => setEditOrganization(!editOrganization)} className="up-btn up-btneditogranisation" type="button">
                    <span>{editOrganization ? t('cancel') : t('editOrganisation')}</span>
                    <i className="icon-pencil"></i>
                </button>}
                {!editOrganization && <Tabs defaultActiveKey={currentTab} onSelect={(e: any) => handleTabSelection(e)} id="uncontrolled-tab-example" className="up-profiletabs">
                    <Tab eventKey="timeline" title={t('timeline')}>
                        {currentTab == 'timeline' && <div className="up-timlines">
                            <div className="up-twocolumns">
                                <div className="up-postsholder">
                                    <div className="up-postsarea">
                                        {(props.myRole.role.policies.includes(EPolicy.CAN_UPLOAD_FILES) || props.myRole.role.policies.includes(EPolicy.CAN_ADD_POSTS)) && <div onClick={() => setCreatePostModal(true)} className="up-createpostarea">
                                            <div className="up-createposthead">
                                                <h3>{t('createUpdate')}</h3>
                                            </div>
                                            <div className="up-createpost">
                                                <figure className="up-organisationogo">
                                                    <img src={props.organization?.avatar ?? images.RestPlaceholder} alt="Organisation Logo" />
                                                </figure>
                                                <div className="up-createpostholder">
                                                    <p>{t('writeUpdateHere')}</p>
                                                </div>
                                            </div>
                                            <ul className="up-selectfilearea">
                                                <li>
                                                    <div className="up-uploadfiles">
                                                        <label>
                                                            <i className="icon-images" />
                                                            <span>{t('photoVideo')}</span>
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="up-uploadfiles">
                                                        <label>
                                                            <i className="icon-file" />
                                                            <span>{t('files')}</span>
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}
                                        {!props.myRole.role.policies.includes(EPolicy.CAN_ADD_POSTS) &&
                                            <div onClick={() => setCreateScheduledPostModal(true)} className="up-createpostarea">
                                            <div className="up-createposthead up-dflex">
                                                <span>
                                                    <img src={images.Rau} />
                                                </span>
                                                <h3>{t('requestUpdate')}</h3>
                                            </div>
                                        </div>}

                                        {organizationPosts && <InfiniteScroll
                                            dataLength={organizationPosts!.length} //This is important field to render the next data
                                            next={() => getOrganizationPosts((paginationInfoPosts?.currentPage! + 1))}
                                            hasMore={organizationPosts!.length !== paginationInfoPosts?.totalRecords!}
                                            loader={<h4>{t('loading')}</h4>}
                                            endMessage={
                                                <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                                    <b>{t('seenItAll')}</b>
                                                </p>
                                            }>
                                            {organizationPosts && organizationPosts.map((item, index) => {
                                                return <OrganizationPost fromAdmin={false} public={false} fromDashboard={true} setLikePost={setLikePost} reportPost={reportPost} fromTimeline={false} key={item._id + index} post={item} showInOrganization={true} setEditPost={editPost} setShareOrganizationPost={sharePostInOrganization} setShareInUserGroupsPost={shareInUserGroupsPost} setDeletePost={deletePost} removeFromList={removeFromList} />
                                            })}
                                        </InfiniteScroll>}

                                    </div>
                                </div>
                                <aside className="up-sidebar">
                                    <div className="up-widget up-widgetaboutorganisation">
                                        <div className="up-widgethead up-textalignleft">
                                            <h3>{t('organisationInfo')}</h3>
                                        </div>
                                        <div className="up-organisationcontent">
                                            <div className="up-description handle-line-breaks">
                                                <p dangerouslySetInnerHTML={{ __html: URLReplacer(props.organization.about ?? t('notAvailable')) }}></p>
                                            </div>
                                            <ul className="up-organisationinfo">
                                                <li>
                                                    <span>{t('email')}</span>
                                                    <span>{props.organization?.email ?? t('notAvailable')}</span>
                                                </li>
                                                <li>
                                                    <span>{t('address')}</span>
                                                    <span>{props.organization?.address ?? t('notAvailable')}</span>
                                                </li>
                                                <li className="up-websitelink">
                                                    <span>{t('web')}</span>
                                                    {props.organization?.website && <span style={{ wordBreak: 'break-word' }}><a href="javascript:void(0);">{props.organization?.website}</a></span>}
                                                    {!props.organization?.website && <span>{t('notAvailable')}</span>}
                                                </li>
                                                <li>
                                                    <span>{t('social')}</span>
                                                    <div className="up-socialicons">
                                                        {props?.organization?.socialLinks && props?.organization?.socialLinks.map((link: string) => (
                                                            <>
                                                                {link && <>
                                                                    {link.includes('facebook') && <a className="up-facebooksocial cursor" onClick={() => openUrl(link)}>
                                                                        <i className="icon-facebook"></i>
                                                                    </a>}
                                                                    {link.includes('twitter') && <a className="up-twittersocial cursor" onClick={() => openUrl(link)}>
                                                                        <i className="icon-twitter"></i>
                                                                    </a>}
                                                                    {link.includes('instagram') && <a className="up-instagramsocial cursor" onClick={() => openUrl(link)}>
                                                                        <i className="icon-instagram"></i>
                                                                    </a>}
                                                                    {link.includes('linkedin') && <a className="up-linkedinsocial cursor" onClick={() => openUrl(link)}>
                                                                        <i className="icon-linkedin"></i>
                                                                    </a>}
                                                                </>}
                                                            </>
                                                        ))}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="up-widget up-widgetallmembers">
                                        <div className="up-widgethead up-textalignleft">
                                            <h3>{t('users')}</h3>
                                        </div>
                                        <div className="up-membersarea">
                                            {organizationAdmins && organizationAdmins.map((member, index) => {
                                                return <div key={member._id} className="up-member">
                                                    <figure className="up-memberimg">
                                                        <img src={member.member?.avatar ? member.member?.avatar : images.UserPlaceholder} alt="Member Image" />
                                                    </figure>
                                                    <div className="up-membername">
                                                        <h4>{member.member?.name}</h4>
                                                        <span>{'@' + member.member?.username}</span>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <a className="up-btn" href="javascript:void(0);">{t('seeAllUsers')}</a>
                                    </div>
                                    <div className="up-widget up-widgetcreateddate">
                                        <div className="up-widgethead up-textalignleft">
                                            <h3>{t('createdDate')}</h3>
                                        </div>
                                        <div className="up-organisationcreateddate">
                                            <span>{props.organization?.createdAt!.split('T')[0] ?? t('notAvailable')}</span>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>}
                    </Tab>
                    {props.myRole.isMember && <Tab eventKey="scheduled" title={t('scheduled')}>
                        {currentTab === 'scheduled' && <ScheduledPostsTab organizationId={id}/>}
                    </Tab>}
                    {props.myRole.isMember && <Tab eventKey="requested" title={<span className="up-textpluscount"><>{t('requested')}
                        {requestedPaginationInfo && requestedPaginationInfo?.totalRecords > 0 && <span className="up-countmessage"> {`${requestedPaginationInfo?.totalRecords}`}</span>}
                    </> </span>}>
                        {currentTab === 'requested' && <RequestedPostsTab organizationId={id} updateCount={updateRequestedPostsCounts}/>}
                    </Tab>}
                    {props.myRole.isMember && <Tab eventKey="events" title={t('events')}>
                        <EventsTab organizationRoles={props?.organizationRoles} memberId={props?.myRole} />
                    </Tab>}
                    {props.myRole.isMember && props.myRole.role.policies.includes(EPolicy.CAN_VIEW_FILES) && <Tab eventKey="media" title={t('media')}>
                        <div className="up-allmediaarea">
                            <div className="up-themeboxhead">
                                <h3>{t('mediaLibrary')}</h3>
                            </div>
                            <div className="up-themebox">
                                <div className="up-mediatabsarea">
                                    <Tabs defaultActiveKey="media" id="uncontrolled-tab-example" className="up-mediatabs">
                                        <Tab eventKey="media" title="Media">
                                            <div className="up-allmediaimages">
                                                {organizationMedia && organizationMedia!.map((item) => (item.medias.map((source: any) => (<div key={source} className="up-mediafile">
                                                    <figure className="up-mediaimg">
                                                        <>
                                                            {source.toString().includes('postVideo') && <video width="400" controls>
                                                                <source src={source} /> </video>}
                                                            {source.toString().includes('postImage')! &&
                                                                <img src={source} alt="up-postimage" />}
                                                        </>
                                                    </figure>
                                                </div>))))}
                                                {organizationMedia && organizationMedia!.length !== organizationMediaPagination?.totalRecords! && <button onClick={() => getOrganizationMedia((organizationMediaPagination?.currentPage! + 1))}> {t('showMore')}</button>}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="files" title="Files">
                                            <div className="up-filesarea">
                                                <div className="up-filebox">
                                                    <div className="up-tablearea">
                                                        <table className="up-filestable table">
                                                            <thead>
                                                                <tr>
                                                                    <th><span>{t('name')}</span></th>
                                                                    <th>&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {organizationFiles && organizationFiles.map((file) => file.medias.map((source: any) => (<tr key={source}>
                                                                    <td>
                                                                        <div className="up-fileiconname">
                                                                            <span className="up-fileicon up-bgproject">
                                                                                <img src={images.FileIcon} alt="File Icon" />
                                                                            </span>
                                                                            <h4>{source.split('postFile')[1]?.toString()?.substring(1)}</h4>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="up-btndropdownholder">
                                                                            <button className="up-btn" onClick={() => window.open(source, "_blank")} type="button">{t('download')}</button>
                                                                        </div>
                                                                    </td>
                                                                </tr>)))}

                                                            </tbody>
                                                        </table>
                                                        {organizationFiles && organizationFiles!.length !== organizationFilesPagination?.totalRecords! && <button onClick={() => getOrganizationFiles((organizationFilesPagination?.currentPage! + 1))}> {t('showMore')}</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </Tab>}
                    {props.myRole.isMember && props.myRole.role.policies.includes(EPolicy.CAN_VIEW_GROUPS) && <Tab eventKey="groups" title={t('groups')}>
                        <div className="up-groupsarea up-tabsallgroups">
                            {breadCrumb.length == 0 && organizationGroups && organizationGroups.length == 0 && <div className="up-themenodata">
                                <div className="up-themenodatacontent">
                                    <h4>{t('noUserGroupCreatedYet')}</h4>
                                    {props.myRole.role.policies.includes(EPolicy.CAN_CREATE_GROUPS) && <button className="up-btn" type="button" onClick={openCreateGroupModal}>{t('createGroup')}</button>}
                                </div>
                            </div>}
                            {organizationGroups && <>
                                <div className="up-themeboxhead">
                                    <h3>{breadCrumb.length > 0 && 'Sub '}{t('userGroups')}<span> ({organizationGroups.length})</span></h3>
                                    <div className="up-searchgroupcreate">
                                        <form className="up-formtheme up-formallmembers">
                                            <fieldset>
                                                <div className="form-group">
                                                    <i className="icon-search"></i>
                                                    <input value={search} onChange={(e) => setSearch(e.target.value)} name="search-members" className="form-control" placeholder={t('searchForGroup')} />
                                                </div>
                                            </fieldset>
                                        </form>
                                        {props.myRole.role.policies.includes(EPolicy.CAN_CREATE_GROUPS) && <button className="up-btn" type="button" onClick={openCreateGroupModal}>{t('createGroup')}</button>}
                                    </div>
                                </div>
                                <div className="up-themebox">
                                    <span className="pl-toplevelgrouptext" onClick={() => getAllGroups()}>{breadCrumb.length == 0 ? t('topLevelUserGroups') : t('home')}</span>
                                    {breadCrumb && breadCrumb.map((bread, index) => (<><span style={{ cursor: 'pointer' }} onClick={() => getSpecficGroupChild(bread, index)} > / {bread.groupName}</span></>))}
                                    <div className="up-allgroups">
                                        {renderGroups()}
                                        {breadCrumb.length !== 0 && organizationGroups.length == 0 && <h4 style={{ marginTop: '16px' }}>{t('noChildGroups')}</h4>}
                                    </div>
                                </div>
                            </>}
                        </div>
                    </Tab>}
                    {props.myRole.isMember && <Tab eventKey="members" title={<span className="up-textpluscount"><>{t('users')}
                        {requestedMembersPaginationInfo && requestedMembersPaginationInfo?.totalRecords > 0 && props?.myRole?.role?.role == 'Admin' && <span className="up-countmessage"> {`${requestedMembersPaginationInfo?.totalRecords}`}</span>}
                    </> </span>}>
                        {currentTab === 'members' && <div className="up-allmemberstab up-ownorganisationmembers">
                            <div className="up-themeboxhead">
                                {!showImportedCodes && <>
                                    {requestedMembersToggle && <h3>{t('users')}</h3>}
                                    {!requestedMembersToggle && <h3>{t('requestedUsers')} <span>{'(' + requestedMembers?.length + ')'}</span></h3>}
                                </>}
                                {showImportedCodes && <h3>{t('assignmentKeys')}</h3>}
                                <div className="up-invitemembers">
                                    {showImportedCodes && props.myRole.role.role == 'Admin' && <button className="up-btninvitemembers up-btn" style={{ marginRight: '8px' }} onClick={() => openDeleteCsvModal()} >{t('deleteKeys')}</button>}
                                    {(showUserFields || showImportedCodes) && props.myRole.role.role == 'Admin' && <button className="up-btninvitemembers up-btn" style={{ marginRight: '8px' }} onClick={() => hideImportedData()} type="button">{t('users')}</button>}
                                    {!showImportedCodes && props.myRole.role.role == 'Admin' && <button className="up-btninvitemembers up-btn" style={{ marginRight: '8px' }} onClick={() => getImportedCodes()} type="button">{t('assignmentKeys')}</button>}
                                    {props.myRole.role.role == 'Admin' && <button className="up-btninvitemembers up-btn" style={{ marginRight: '8px' }} onClick={() => getUserFields()} type="button">{t('userFields')}</button>}
                                    {props.myRole.role.role == 'Admin' && showImportedCodes && <button className="up-btninvitemembers up-btn" style={{ marginRight: '8px' }} onClick={() => setShowImportCsvModal(!showImportCsvModal)} type="button">{t('addKeys')}</button>}
                                    {props.myRole.role.role == 'Admin' && <button className='up-btninvitemembers up-btn' style={{ marginRight: '8px' }} onClick={handleShowfive}>{t('inviteMembers')}</button>}
                                    {props.myRole.role.role == 'Admin' && <button className="up-btninvitemembers up-btn up-btnjoinaccept" onClick={() => {
                                        setShowImportedCodes(false);
                                        setShowImportedCodes(false);
                                        setShowUserFields(false)
                                        setRequestedMembersToggle(!requestedMembersToggle)
                                    }} type="button">{requestedMembersToggle ? <span className="up-textpluscount m-0">{t('joiningRequest')} {
                                        requestedMembersPaginationInfo?.totalRecords! > 0 && <span
                                            className="up-countmessage m-0"> {requestedMembersPaginationInfo?.totalRecords} </span>}</span> : t('allMembers')}</button>}
                                </div>
                            </div>
                            {!showImportedCodes && <>
                                {!showUserFields && <>
                                    {requestedMembersToggle && <div className="up-themebox">
                                        <form className="up-formtheme up-formallmembers">
                                            <fieldset>
                                                <div className="form-group">
                                                    <i className="icon-search"></i>
                                                    <input type="search" name="search" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder={t('searchForMembers')} />
                                                </div>
                                            </fieldset>
                                        </form>
                                        <div className="up-membersarea">
                                            <Tabs defaultActiveKey="Admin" onSelect={(e: any) => handleMemberTabSelection(e)}>
                                                <Tab eventKey="Admin" title={t('admin')}>
                                                    {selectedMemberTab === 'Admin' && <div>
                                                        <InfiniteScroll
                                                            dataLength={organizationAdmins!.length} //This is important field to render the next data
                                                            next={() => getOrganizationMembers(organizationMembersPaginationInfo?.currentPage! + 1, 'Admin')}
                                                            hasMore={(organizationAdmins.length) !== organizationMembersPaginationInfo?.totalRecords!}
                                                            loader={<h4>{t('loading')}</h4>}
                                                            endMessage={
                                                                <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                                                    <b>{t('noMoreAdmins')}</b>
                                                                </p>
                                                            }>
                                                            <div className="up-membersarea">
                                                                {organizationAdmins && organizationAdmins!.length > 0 && <div className="up-memberhead">
                                                                    <h4>{t('admin')}</h4>
                                                                </div>}
                                                                {organizationAdmins && organizationAdmins.map((item, index) => {
                                                                    return <div key={item._id} className="up-member">
                                                                        <figure className="up-memberimg" style={{ cursor: 'pointer' }} onClick={() => openUserProfile(item)}>
                                                                            <img src={item.member?.avatar ? item.member?.avatar : images.UserPlaceholder} alt="Member Image" />
                                                                        </figure>
                                                                        <div className="up-membername">
                                                                            <div className="up-membernameuserarea">
                                                                                <h4 style={{ cursor: 'pointer' }} onClick={() => openUserProfile(item)}>{item.member?.name}</h4>
                                                                                <span style={{ cursor: 'pointer' }} onClick={() => openUserProfile(item)}>{'@' + item.member?.username}</span>
                                                                            </div>
                                                                            {props.myRole?.role?.role === 'Admin' && <div className="up-membersiconholder">
                                                                                <button type="button" title={t('removeUser')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => removeMemberFromOrganization(item, index)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                                                                                </button>
                                                                                <button type="button" title={t('changeRole')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => openMemberRoleChangeModal(item)}>
                                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.86 181">
                                                                                        {/* <defs>
                                <style>
                                .cls-1 {
                                    fill: '#fff'
                                }

                                .cls-2 {
                                    fill: none;
                                }
                                </style>
                            </defs> */}
                                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M138.4,123.84v6.48h-21.08v-6.48c0-5.82,4.72-10.54,10.54-10.54,2.91,0,5.55,1.18,7.45,3.09,1.91,1.9,3.09,4.54,3.09,7.45Z"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M127.86,94.94c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.26,43-43-19.25-43-43-43ZM149.71,161.32c0,3.16-2.56,5.72-5.71,5.72h-32.28c-3.15,0-5.71-2.56-5.71-5.72v-25.28c0-3.16,2.56-5.72,5.71-5.72h.94v-6.29c0-8.39,6.81-15.19,15.2-15.19,4.2,0,7.99,1.7,10.75,4.45,2.74,2.75,4.45,6.55,4.45,10.74v6.29h.94c3.15,0,5.71,2.56,5.71,5.72v25.28Z"/>
                                                                                            <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                                                                            <g>
                                                                                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                                <button type="button" title={t('validity')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() =>  updateValidity(item)}>
                                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.77 181">
                                                                                        {/* <defs>
                                    <style>
                                    .cls-1 {
                                        fill: '#fff'
                                    }

                                    .cls-2 {
                                        fill: 'none'
                                    }
                                    </style>
                                </defs> */}
                                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M128.77,94.59c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.25,43-43-19.25-43-43-43ZM153.24,159.53c0,3.07-2.49,5.56-5.56,5.56h-37.82c-3.07,0-5.56-2.49-5.56-5.56v-37.82c0-3.07,2.49-5.56,5.56-5.56h6.37c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.79-2.8.78,0,1.48.31,1.98.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h14.06c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.8-2.8.77,0,1.47.31,1.97.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h6.37c3.07,0,5.56,2.49,5.56,5.56v37.82Z"/>
                                                                                            <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                                                                            <path className="cls-2" style={{fill: 'none'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                                <button type="button" title={t('assignUserFields')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => {
                                                                                    setMemberSelectedForRoleChange(item)
                                                                                    setShowFieldsModal(true)
                                                                                }}>
                                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.59 110.67">
                                                                                        <defs>
                                                                                            {/* <style>
                                    .cls-1 {
                                    fill: '#fff';
                                    {"}"}
                                </style> */}
                                                                                        </defs>
                                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                                            <g>
                                                                                                <circle className="cls-1" style={{fill: '#fff'}} cx="148.52" cy="26.15" r="26.15"/>
                                                                                                <path className="cls-1" style={{fill: '#fff'}} d="M187.43,110.67h-77.82c-8.19,0-14.04-7.94-11.61-15.76,6.71-21.52,26.79-37.15,50.52-37.15s43.81,15.62,50.52,37.15c2.44,7.82-3.42,15.76-11.61,15.76Z"/>
                                                                                                <rect className="cls-1" style={{fill: '#fff'}} x=".11" y="15.81" width="101.32" height="17.74" rx="8.87" ry="8.87"/>
                                                                                                <rect className="cls-1" style={{fill: '#fff'}} x="0" y="75.4" width="67.55" height="17.74" rx="8.87" ry="8.87"/>
                                                                                                <rect className="cls-1" style={{fill: '#fff'}} x=".44" y="46.22" width="101" height="17.74" rx="8.87" ry="8.87"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                                <button type="button" title={t('assignUserGroups')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => {
                                                                                    setMemberSelectedForRoleChange(item)
                                                                                    setShowGroupModal(true)
                                                                                }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM609.3 512l-137.8 0c5.4-9.4 8.6-20.3 8.6-32l0-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2l61.4 0C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/></svg>
                                                                                </button></div>}
                                                                            <Dropdown className="up-themedropdown" style={{ top: '7px' }}>
                                                                                {props.myRole?.role?.role === 'Admin' && <Dropdown.Toggle id="dropdown-basic">
                                                                                    <i className="icon-menu-icon"></i>
                                                                                </Dropdown.Toggle>}
                                                                                <Dropdown.Menu className="up-themedropdownmenu">
                                                                                    <Dropdown.Item href="javascript:void(0);" onClick={() => openMemberRoleChangeModal(item)}>{t('changeRole')}</Dropdown.Item>
                                                                                    <Dropdown.Item href="javascript:void(0);" onClick={() => openUserProfile(item)} >{t('viewDetail')}</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </InfiniteScroll>
                                                    </div>}
                                                </Tab>
                                                <Tab eventKey="Member" title={t('users')}>
                                                    {selectedMemberTab === 'Member' && <div>
                                                        <InfiniteScroll
                                                            dataLength={organizationMembers.length} //This is important field to render the next data
                                                            next={() => getOrganizationMembers(organizationMembersPaginationInfo?.currentPage! + 1, 'Member')}
                                                            hasMore={(organizationMembers.length) !== organizationMembersPaginationInfo?.totalRecords!}
                                                            loader={<h4>{t('loading')}</h4>}
                                                            endMessage={
                                                                <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                                                    <b>{t('noMoreUsers')}</b>
                                                                </p>
                                                            }>
                                                            <div className="up-membersarea">
                                                                {organizationMembers && organizationMembers!.length > 0 && <div className="up-memberhead">
                                                                    <h4>{t('users')}</h4>
                                                                </div>}
                                                                {organizationMembers && organizationMembers.map((item, index) => {
                                                                    return <div key={item._id} className="up-member">
                                                                        <figure className="up-memberimg" style={{ cursor: 'pointer' }} onClick={() => openUserProfile(item)}>
                                                                            <img src={item.member?.avatar ? item.member?.avatar : images.UserPlaceholder} alt="Member Image" />
                                                                        </figure>
                                                                        <div className="up-membername">
                                                                            <div className="up-membernameuserarea">
                                                                                <h4 style={{ cursor: 'pointer' }} onClick={() => openUserProfile(item)}>{item.member?.name}</h4>
                                                                                <span style={{ cursor: 'pointer' }} onClick={() => openUserProfile(item)}>{"@" + item.member?.username} {"  (" + item.role.role + ")"}</span>
                                                                            </div>
                                                                            {props.myRole?.role?.role === 'Admin' && <div className="up-membersiconholder">
                                                                                <button type="button" title={t('removeUser')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => removeMemberFromOrganization(item, index)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                                                                                </button>
                                                                                <button type="button" title={t('changeRole')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => openMemberRoleChangeModal(item)}>
                                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.86 181">
                                                                                        {/* <defs>
                                <style>
                                .cls-1 {
                                    fill: '#fff'
                                }

                                .cls-2 {
                                    fill: none;
                                }
                                </style>
                            </defs> */}
                                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M138.4,123.84v6.48h-21.08v-6.48c0-5.82,4.72-10.54,10.54-10.54,2.91,0,5.55,1.18,7.45,3.09,1.91,1.9,3.09,4.54,3.09,7.45Z"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M127.86,94.94c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.26,43-43-19.25-43-43-43ZM149.71,161.32c0,3.16-2.56,5.72-5.71,5.72h-32.28c-3.15,0-5.71-2.56-5.71-5.72v-25.28c0-3.16,2.56-5.72,5.71-5.72h.94v-6.29c0-8.39,6.81-15.19,15.2-15.19,4.2,0,7.99,1.7,10.75,4.45,2.74,2.75,4.45,6.55,4.45,10.74v6.29h.94c3.15,0,5.71,2.56,5.71,5.72v25.28Z"/>
                                                                                            <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                                                                            <g>
                                                                                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                               <button type="button" title={t('validity')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() =>  updateValidity(item)}>
                                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.77 181">
                                                                                        {/* <defs>
                                    <style>
                                    .cls-1 {
                                        fill: '#fff'
                                    }

                                    .cls-2 {
                                        fill: 'none'
                                    }
                                    </style>
                                </defs> */}
                                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M128.77,94.59c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.25,43-43-19.25-43-43-43ZM153.24,159.53c0,3.07-2.49,5.56-5.56,5.56h-37.82c-3.07,0-5.56-2.49-5.56-5.56v-37.82c0-3.07,2.49-5.56,5.56-5.56h6.37c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.79-2.8.78,0,1.48.31,1.98.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h14.06c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.8-2.8.77,0,1.47.31,1.97.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h6.37c3.07,0,5.56,2.49,5.56,5.56v37.82Z"/>
                                                                                            <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                                                                            <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                                                                            <path className="cls-2" style={{fill: 'none'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                                <button type="button" title={t('assignUserFields')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => {
                                                                                    setMemberSelectedForRoleChange(item)
                                                                                    setShowFieldsModal(true)
                                                                                }}>
                                                                                    <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.59 110.67">
                                                                                        <defs>
                                                                                            {/* <style>
                                    .cls-1 {
                                    fill: '#fff';
                                    {"}"}
                                </style> */}
                                                                                        </defs>
                                                                                        <g id="Laag_1-2" data-name="Laag_1">
                                                                                            <g>
                                                                                                <circle className="cls-1" style={{fill: '#fff'}} cx="148.52" cy="26.15" r="26.15"/>
                                                                                                <path className="cls-1" style={{fill: '#fff'}} d="M187.43,110.67h-77.82c-8.19,0-14.04-7.94-11.61-15.76,6.71-21.52,26.79-37.15,50.52-37.15s43.81,15.62,50.52,37.15c2.44,7.82-3.42,15.76-11.61,15.76Z"/>
                                                                                                <rect className="cls-1" style={{fill: '#fff'}} x=".11" y="15.81" width="101.32" height="17.74" rx="8.87" ry="8.87"/>
                                                                                                <rect className="cls-1" style={{fill: '#fff'}} x="0" y="75.4" width="67.55" height="17.74" rx="8.87" ry="8.87"/>
                                                                                                <rect className="cls-1" style={{fill: '#fff'}} x=".44" y="46.22" width="101" height="17.74" rx="8.87" ry="8.87"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                                <button type="button" title={t('assignUserGroups')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => {
                                                                                    setMemberSelectedForRoleChange(item)
                                                                                    setShowGroupModal(true)
                                                                                }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM609.3 512l-137.8 0c5.4-9.4 8.6-20.3 8.6-32l0-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2l61.4 0C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/></svg>
                                                                                </button></div>}
                                                                            <Dropdown className="up-themedropdown" style={{ top: '7px' }}>
                                                                                {props.myRole?.role?.role === 'Admin' && <Dropdown.Toggle id="dropdown-basic">
                                                                                    <i className="icon-menu-icon"></i>
                                                                                </Dropdown.Toggle>}
                                                                                <Dropdown.Menu className="up-themedropdownmenu">
                                                                                    <Dropdown.Item onClick={() => openMemberRoleChangeModal(item)}>{t('changeRole')}</Dropdown.Item>
                                                                                    <Dropdown.Item onClick={() => updateValidity(item)}>{t('updateValidity')}</Dropdown.Item>
                                                                                    <Dropdown.Item onClick={() => removeMemberFromOrganization(item, index)} >{t('remove')}</Dropdown.Item>
                                                                                    <Dropdown.Item href="javascript:void(0);" >{t('blockMember')}</Dropdown.Item>
                                                                                    <Dropdown.Item href="javascript:void(0);" onClick={() => openUserProfile(item)} >{t('viewDetail')}</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </InfiniteScroll>
                                                    </div> }
                                                </Tab>
                                            </Tabs>
                                        </div>
                                        {/*{organizationMembers && organizationAdmins && <>*/}
                                        {/*    {(organizationMembers!.length + organizationAdmins!.length) !== organizationMembersPaginationInfo?.totalRecords! && <a className="up-btn" href="javascript:void(0);">See All Members</a>}*/}
                                        {/*</>}*/}
                                    </div>}
                                    {!requestedMembersToggle && <div className="up-themebox">
                                        <div className="up-membersarea">
                                            <div className="up-memberhead">
                                                <h4>{t('requestedMembers')}</h4>
                                            </div>
                                            {requestedMembers && requestedMembers.map((item: IOrganizationRequestedMembers, index: number) => {
                                                return <div className="up-member" key={item._id}>
                                                    <figure className="up-memberimg">
                                                        <img src={item.member?.avatar ? item.member?.avatar : images.UserPlaceholder} alt="Member Image" />
                                                    </figure>
                                                    <div className="up-membername">
                                                        <h4> <a href={`/memberprofilepage/${item.member._id}`}>{item.member?.name} </a> {item.comment && item.comment.length > 0 && <i className="icon-info" onClick={() => openCommentModals(item, index)} style={{ marginLeft: '5px', cursor: 'pointer' }}></i>} </h4>
                                                        <span>{'@' + item.member?.username}</span>
                                                        <Dropdown className="up-themedropdown">
                                                            {props.myRole?.role?.role === 'Admin' && <Dropdown.Toggle id="dropdown-basic">
                                                                <i className="icon-menu-icon"></i>
                                                            </Dropdown.Toggle>}
                                                            <Dropdown.Menu className="up-themedropdownmenu">
                                                                <Dropdown.Item onClick={() => openValidModal('ACCEPTED', item._id, index)} >{t('accept')}</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => updateRequestedFollowStatus('OPEN_MODAL', item._id, index)} >{t('reject')}</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>}</>}
                            </>}
                            {showUserFields && <div className="up-themebox">
                                <div className="up-membersarea">
                                    <div className="up-adduserfieldbtnholder">
                                        <button className=' up-btn' onClick={addUserFields}>{t('addUserFields')}</button>
                                    </div>
                                    <div className="up-adduserfieldmain">
                                        {userFields.length > 0 && userFields.map((item,index) => (<div className="up-editprofileinputs">
                                            <div className="up-adduserfieldinputholder">
                                                <div className="form-group">
                                                    <div className="up-inputwithicon">
                                                        <i className="icon-envelop"></i>
                                                        <input type="text" onChange={e => handleFieldChange(e,index)} value={item.label} />
                                                    </div>
                                                </div>
                                                <ul className="up-saveeditbtns">
                                                    <li>
                                                        <button onClick={() => saveUserField(item,index)}
                                                                className="up-editbtnpencil" type="button">
                                                            {item._id ? t('update') :t('save')}
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => deleteUserField(item,index)}
                                                                className="up-editbtnpencil" type="button">
                                                            {t('delete')}
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>))
                                        }
                                    </div>
                                    {userFields.length === 0 && <h5>{t('noUserFields')}</h5>}
                                </div>
                            </div>}
                            {showImportedCodes && importedCodes?.length > 0 &&
                                <InfiniteScroll
                                    dataLength={importedCodes!.length} //This is important field to render the next data
                                    next={() => getImportedCodes((importedCodesPagination?.currentPage! + 1))}
                                    hasMore={importedCodes!.length !== importedCodesPagination?.totalRecords!}
                                    loader={<h4>{t('loading')}</h4>}
                                    endMessage={
                                        <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                            <b>{t('seenItAll')}</b>
                                        </p>
                                    }>
                                    {importedCodes && <div className="up-themebox">
                                        <div className="up-membersarea">
                                            <table className="up-filestable table up-responsivetable">
                                                <thead>
                                                    <tr>
                                                        <th><span>{t('fileName')}</span></th>
                                                        <th><span>{t('key')}</span></th>
                                                        <th><span>{t('userGroups')}</span></th>
                                                        <th><span>{t('organizationRole')}</span></th>
                                                        <th><span>{t('createdAt')}</span></th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {importedCodes && importedCodes!.map((item: any, index: number) => (<tr key={item.organization + index}>
                                                        <td data-title="File Name">
                                                            {item?.csvFileName}
                                                        </td>
                                                        <td data-title="Key">
                                                            <span>{item?.code} {item?.isUsed !== 'NO' && <i className="icon-info"
                                                                                   onClick={() => openCodeLogModal(item, index)}
                                                                                   style={{
                                                                                       marginLeft: '5px',
                                                                                       cursor: 'pointer'
                                                                                   }}></i>}</span>
                                                        </td>
                                                        <td data-title="User Group">
                                                            {item?.groups && item?.groups.map((group: any, index: number) => (<h5 key={group._id + index}>{group.name}</h5>))}
                                                            {item?.groups?.length == 0 && <h5>{t('userGroupNotFound')}</h5>}
                                                        </td>
                                                        <td data-title="Organization Role">
                                                            {item?.organizationRole && <h5>{item?.organizationRole.role}</h5>}
                                                            {!item?.organizationRole && <h5>{t('organizationRoleNotFound')}</h5>}
                                                        </td>
                                                        <td data-title="Organization Role">
                                                            {moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                                        </td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                </InfiniteScroll>}
                            {showImportedCodes && importedCodes?.length == 0 &&
                                <h5>{t('noKeysImportedYet')}</h5>}
                        </div>}
                    </Tab>}
                    {props.myRole.isMember && <Tab eventKey="settings" title={t('settings')}>
                        {currentTab == 'settings' && <div className="up-editogranisationtabs">
                            <Tabs defaultActiveKey="userroles" id="uncontrolled-tab-example">
                                <Tab eventKey="userroles" title="">
                                    {(organizationAdmins || organizationMembers) && <SettingsTab organizationName={props?.organization?.name} myRole={props.myRole} deleteOrganizationRole={deleteOrganizationRole} setOrganizationRole={setOrganizationRole} organizationId={id} organizationRoles={props?.organizationRoles} organizationAdmins={organizationAdmins!} organizationMembers={organizationMembers!} />}
                                </Tab>
                            </Tabs>
                        </div>}
                    </Tab>}
                    <Tab eventKey="about" title={t('about')}>
                        <div className="up-organisationaboutinfo">
                            <div className="up-themebox">
                                <div className="up-organisationcontent">
                                    <div className="up-themeboxhead">
                                        <h3>{t('description')}</h3>
                                    </div>
                                    <div className="up-description">
                                        <p>{props.organization?.about ?? t('notAvailable')}</p>
                                    </div>
                                    <div className="up-contactorganisation">
                                        <div className="up-themeboxhead">
                                            <h3>{t('contact')}</h3>
                                        </div>
                                        <ul className="up-organisationinfo">
                                            <li>
                                                <span>{t('email')}</span>
                                                <span>{props.organization?.email ?? t('notAvailable')}</span>
                                            </li>
                                            <li>
                                                <span>{t('address')}</span>
                                                <span>{props.organization?.address ?? t('notAvailable')}</span>
                                            </li>
                                            {/* <li> */}
                                            {/* <span>Phone: </span> */}
                                            {/* <span>+123 9856836</span> */}
                                            {/* </li> */}
                                            <li className="up-websitelink">
                                                <span>{t('web')}</span>
                                                {props.organization?.website && <span><a href="javascript:void(0);">{props.organization?.website}</a></span>}
                                                {!props.organization?.website && <span>{t('notAvailable')}</span>}
                                            </li>
                                            <li>
                                                <span>{t('social')}</span>
                                                <div className="up-socialicons">
                                                    <a className="up-twittersocial" href="javascript:void(0);">
                                                        <i className="icon-twitter"></i>
                                                    </a>
                                                    <a className="up-linkedinsocial" href="javascript:void(0);">
                                                        <i className="icon-linkedin"></i>
                                                    </a>
                                                    <a className="up-facebooksocial" href="javascript:void(0);">
                                                        <i className="icon-facebook"></i>
                                                    </a>
                                                    <a className="up-instagramsocial" href="javascript:void(0);">
                                                        <i className="icon-instagram"></i>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="up-organisationhistory">
                                        <div className="up-themeboxhead">
                                            <h3>{t('history')}</h3>
                                        </div>
                                        <ul className="up-organisationinfo">
                                            <li>
                                                <span>{t('createdDate')}</span>
                                                <span>{props.organization?.createdAt!.split('T')[0] ?? t('notAvailable')}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="up-organisationhistory">
                                        <div className="up-themeboxhead">
                                            <h3>{t('parentOrganisation')}</h3>
                                        </div>
                                        {props.organization?.parentOrganization && <span>{props.organization.parentOrganization.name}</span>}
                                        {!props.organization?.parentOrganization && props.myRole?.role?.role === 'Admin' && <ul className="up-organisationinfo">
                                            <li>
                                                <span>{t('none')}</span>
                                                <button className="up-btnaddorganisation" type="button" onClick={handleShowfour}>
                                                    <span>{t('add')}</span>
                                                </button>
                                            </li>
                                        </ul>}
                                        {!props.organization?.parentOrganization && props.myRole?.role?.role !== 'Admin' && <ul className="up-organisationinfo">
                                            <li>
                                                <span>{t('none')}</span>
                                            </li>
                                        </ul>}

                                    </div>
                                    <div className="up-organisationhistory up-chilsorganisation">
                                        <div className="up-themeboxhead">
                                            <h3>{t('childOrganisation')}</h3>
                                            {props.organization?.childOrganization && props.organization?.childOrganization.map((item, index) => {
                                                return <span key={item._id}>{item?.name}</span>
                                            })}
                                            {props.organization?.childOrganization!.length === 0 && <span>{t('none')}</span>}
                                        </div>
                                    </div>
                                    {requestedChildOrganizations && <div className="up-organisationhistory up-chilsorganisation">
                                        <div className="up-themeboxhead">
                                            <h3>{t('requestedChildOrganisations')}</h3>
                                            {requestedChildOrganizations.length > 0 && requestedChildOrganizations.map((item, index) => {
                                                return <ul key={item._id} className="up-organisationinfo" style={{ marginTop: '4px' }}>
                                                    <li>
                                                        <span>{item.name}</span>
                                                        <button className="up-btnaddorganisation" style={{ marginRight: '8px' }} onClick={() => parentOrganizationStatusChange(item._id, 'YES', index)}>{t('accept')}</button>
                                                        <button className="up-btnaddorganisation" onClick={() => parentOrganizationStatusChange(item._id, 'NO', index)}>{t('reject')}</button>
                                                    </li>
                                                </ul>
                                            })}
                                            {requestedChildOrganizations.length === 0 && <span>{t('none')}</span>}
                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>}
                {/* Edit Organisation Start */}
                {editOrganization && <EditOrganization organization={props.organization} setOrganization={setOrganization} />}
                {/* Edit Organisation End */}
            </div>
            {/* Create An Update */}
            {createPostModal && <CreatePostModall id={id} currentOrganizationAvatar={props.organization?.avatar} organizationPosts={organizationPosts!} selectedEditPost={selectedEditPost!} isEditPost={isEditPost} setOrganizationPosts={setPosts} closeModal={handleClose} organizationRoles={props.organizationRoles} />}
            {createScheduledPostModal && <CreateScheduledPostModal id={id} showSelectionButtons={false} currentOrganizationAvatar={props.organization?.avatar} organizationPosts={organizationPosts!} selectedEditPost={selectedEditPost!} isEditPost={isEditPost} setOrganizationPosts={setPosts} closeModal={handleClose} />}
            {/* Display To Modal */}
            {displayToModal && <DisplayToModal organizationRoles={props?.organizationRoles} publicChangeHandler={publicChangeHandler} isPublicChecked={isPublicChecked} closeModal={closeDisplayToModal} handleRoleSelection={handleRoleSelection} />}
            {/* Custom Option Modal */}
            <Modal className="up-thememodal up-modalcustomshow" show={showthree} onHide={handleClosethree}>
                <Modal.Header className="up-modalheader" closeButton>
                    <Modal.Title>Custom Display Audience</Modal.Title>
                </Modal.Header>
                <Modal.Body className="up-modalbody">
                    <div className="up-modalcontentholder">
                        <form className="up-formtheme up-forcustomshowaudience">
                            <fieldset>
                                <div className="form-group">
                                    <label>Include user group</label>
                                    <input type="search" name="search-users" className="form-control" placeholder="Search user group" />
                                </div>
                                <div className="form-group">
                                    <label>Exclude user group</label>
                                    <input type="search" name="exclude-groups" className="form-control" placeholder="Search user group" />
                                </div>
                                <div className="form-group">
                                    <label>Include members</label>
                                    <input type="search" name="include-members" className="form-control" placeholder="Search member" />
                                </div>
                                <div className="form-group">
                                    <label>Exclude members</label>
                                    <input type="search" name="exclude-members" className="form-control" placeholder="Search member" />
                                </div>
                                <a href="/create-organisation-step-two" className="up-btn">Apply</a>
                            </fieldset>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            {/* REPORT POST MODAL */}
            {showReportPostModal && <ReportPostModal closeModal={closeReportPostModal} />}
            {/* Search Parent Organisation Modal */}
            <Modal className="up-thememodal up-modalcustomshow" show={showfour} onHide={handleClosefour}>
                {/*  <Modal.Header className="up-modalheader" closeButton>
                <Modal.Title>Custom Display Audience</Modal.Title>
            </Modal.Header> */}
                <Modal.Body className="up-modalbody">
                    <div className="up-modalcontentholder">
                        <form onSubmit={handleSubmit(onSubmit)} className="up-formtheme up-forcustomshowaudience">
                            <fieldset>
                                <div className="form-group">
                                    <label>{t('searchParentOrganisation')}</label>
                                    <AsyncSelect className="up-async-select" {...register("parentOrganization")} onChange={onChangeSelectedOption} cacheOptions defaultOptions loadOptions={promiseOptions} />
                                </div>
                                <button className="up-btn" type="submit">{t('send')}</button>
                            </fieldset>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Invite Members Modal */}
            {showInviteModal && <InviteMembersModal closeModal={handleClosefive} id={id} />}

            {/* Reject Reason Modal */}
            {openRejectionModal && <RequestRejectionModal openRejectionModal={openRejectionModal} closeModal={rejectionCloseModal} />}

            {/* Valid Till Modal */}
            {openValidTillModal && <ValidTillModal updateMemberValidity={updateMemberValidity} member={memberSelectedForRoleChange} closeModal={validTillCloseModal} />}

            {/* Code log Modal */}
            {codeLogModal && <KeyLogModal closeModal={closeCodeLogModal} id={id} code={selectedCode} />}

            {/* Delete CSV Modal */}
            {deleteCsvModal && <DeleteCSVModal closeModal={closeDeleteCsvModal} id={id} />}

            {/* Import CSV Modal */}
            {showImportCsvModal && <ImportCodeCSVModal closeModal={closeCSVModal} id={id} />}
            {/* Create Group Modal */}
            {createGroupModal && <CreateGroupModal organizationMembers={organizationMembers} organizationAdmins={organizationAdmins} closeModal={closeGroupCreationModal} id={id} selectedGroup={selectedGroup!} myRole={props.myRole} isEdit={isEditGroup} />}
            {sharePostModal && <SharePostOrganizationModal postToBeShared={postToBeSharedInOrganization!} userOrganizations={userOrganizations!} closeModal={closeSharePostModal} />}
            {sharePostInUserGroupsModal && <SharePostInUserGroupModal postToBeShared={postToBeSharedInOrganization!} userGroups={userGroups!} closeModal={closeSharePostModal} />}
            {openCommentModal && <SendFollowRequestModal closeModal={() => setOpenCommentModal(false)} isEdit={true} selectedComment={selectedComment} />}
            {/* Member Role Change Modal */}
            {showMemberRoleChangeModal && <MemberRoleChangeModal closeModal={closeMemberRoleChangeModal} organizationRoles={props?.organizationRoles} selectedMember={memberSelectedForRoleChange!} updateMemberRole={updateMemberRole} />}
            {showGroupModal && <UpdateUserGroupModal memberName={memberSelectedForRoleChange?.member?.name} memberId={memberSelectedForRoleChange._id} organizationId={id} closeModal={closeGroupModal} />}
            {showFieldsModal && <AssignUserFieldsModal orgComment={''} memberName={memberSelectedForRoleChange?.member?.name} memberId={memberSelectedForRoleChange._id} organizationId={id} closeModal={closeFieldsModal} />}
            {/*USER ROLE GROUP FIELD MODAL*/}
            {userRoleGroupFieldModal && <UserRoleGroupFieldModal updateMemberValidity={updateMemberValidity} organizationId={id} closeModal={closeRoleGroupFieldChangeModal} organizationRoles={props?.organizationRoles} selectedMember={memberSelectedForRoleChange!} updateMemberRole={updateMemberRole} />}
        </>
    )

}
