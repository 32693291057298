// import { AppLayout } from "../../components/layouts/AppLayout"

import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { images } from "../../assets/images"
import { AppLayout } from "../../components/layouts/AppLayout"
import { IIdFromUrl } from "../../interfaces/id-from-url.interface";
import { organisationService } from "../../services/organisation.service";
import { Toast } from "../../utils/Toast";
import { getUser } from "../auth/slice/user.selector";
import { IOrganization, IOrganizationBannerAvatar } from "../organisations/createorganisation/interfaces/organisation.interface";
import { IOrganizationRequestedMembers, IOrganizationRole } from "../organisations/createorganisation/interfaces/organization-requested-members.interface";
import { OwnOrganisationProfileTabs } from "./components/OwnOrganisationProfileTabs"
import { S3 } from 'aws-sdk';
import { AWS_S3_BUCKET, s3 } from "../../config/aws.config";
import { FullPageLoader } from "../../utils/FullPageLoader";
import { ImageCropper } from "../../utils/ImageCropper";
import { IFollowOrganizationRequestStatusIds } from "../organisations/createorganisation/interfaces/child-organisation-status-update.interface";
import { SendFollowRequestModal } from "../auth/pages/follow-organisations/FollowRequestModal";
import Swal from "sweetalert2";
import Badge from 'react-bootstrap/Badge';
import {EBoolean} from "../auth/pages/signup/interfaces/drop-down.enums";
import {useTranslation} from "react-i18next";

export const OwnOrganisationProfile = () => {
    const [isLoading, setIsloading] = useState(true);
    const [organization, setOrganization] = useState<IOrganization>();
    const [myRole, setMyRole] = useState<IOrganizationRequestedMembers>({
        isBlocked: "NO",
        member: {
            _id: 'string',
            name: 'string',
            username: 'string',
            email: 'string'
        },
        organization: "",
        role: {
            _id: 'string',
            role: 'string',
            policies: [],
        },
        status: "",
        _id: "",
        isMember: false,
        _v: 0
    });
    const [selectedBannerFile, setSelectedBannerFile] = useState<any>()
    const [bannerPreview, setBannerPreview] = useState<any>()
    const [showImageCropper, setShowImageCropper] = useState(false);
    const [selectedAvatarFile, setSelectedAvatarFile] = useState<any>()
    const [avatarPreview, setAvatarPreview] = useState<any>()
    const [organizationRoles, setOrganizationRoles] = useState<IOrganizationRole[]>()
    const [currentTab, setCurrentTab] = useState<string>()
    const [isMember, setIsMember] = useState<string>('')
    const [opneModal, setOpenModal] = useState(false);
    const [orgName, setOrgName] = useState<string>()
    let { id } = useParams<IIdFromUrl>();
    const [organizationAdmins, setOrganizationAdmins] = useState<IOrganizationRequestedMembers[]>([]);
    const currentUser = useSelector(getUser);
    const { t } = useTranslation('', { keyPrefix: 'OwnOrganisationProfile' });
    const bannerRef = useRef<HTMLInputElement>(null);
    const avatarRef = useRef<HTMLInputElement>(null);
    const location = useLocation();

    useEffect(() => {
        allCalls()
    }, [])
    useEffect(() => {
    }, [organization, , setOrganization])

    const allCalls = async () => {
        setIsloading(true)
        await Promise.all([getMyOrganizationRole(id),
        getOrganizationById(id),
        getOrganizationRoles(id)])
        setTimeout(() => {
            switch (location.search) {
                case '?requested':
                    setCurrentTab('members')
                    break;
                default:
                    setCurrentTab("timeline")
            }
            setIsloading(false)
        }, 250)
    }
    useEffect(() => {
        if (!selectedBannerFile) {
            setBannerPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedBannerFile)
        setBannerPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedBannerFile])

    useEffect(() => {
        if (!selectedAvatarFile) {
            setAvatarPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedAvatarFile)
        setAvatarPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedAvatarFile])


    const getOrganizationRoles = async (id: string) => {
        const res: any = await organisationService.getOrganizationRoles(id);
        if (res.statusCode == 200) {
            // const temp = res.payload.organizationRoles.filter((role: any) => role.role !== 'Admin');
            for (const iterator of res.payload.organizationRoles) {
                iterator.checked = false;
            }
            setOrganizationRoles(res.payload.organizationRoles)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getOrganizationById = async (id: string) => {
        const res: any = await organisationService.getOrganizationById(id);
        if (res.statusCode == 200) {
            setIsMember(res.payload.isMember);
            setOrgName(res.payload.organization.name)
            setOrganization(res.payload.organization);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getMyOrganizationRole = async (orgId: string) => {
        const res: any = await organisationService.getMyOrganizationRole(orgId);
        if (res.statusCode == 200) {
            if (res.payload.organizationMember && res.payload.organizationMember.status == "ACCEPTED") {
                res.payload.organizationMember.isMember = true;
                setMyRole(res.payload.organizationMember)
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const onBannerImageClick = () => {
        if (bannerRef.current) {
            bannerRef.current.click();
        }
    };
    const onAvatarImageClick = () => {
        if (avatarRef.current) {
            avatarRef.current.click();
        }
    };
    const bannerImageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedBannerFile(e.target.files[0]);
            setShowImageCropper(true);
        }
    };
    const avatarImageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedAvatarFile(e.target.files[0]);
        }
    };
    const uploadBannerImage = async (file: any) => {
        setIsloading(true);
        let data: IOrganizationBannerAvatar = {
        };
        if (selectedBannerFile) {
            const params: S3.PutObjectRequest = {
                Body: file,
                Bucket: AWS_S3_BUCKET!,
                Key: `${process.env.REACT_APP_ENV}/${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedBannerFile.name}`,
                ContentType: selectedBannerFile.type,
                // ACL: "public-read",
            };
            const uploadResult: any = await s3.upload(params).promise();
            data.banner = uploadResult.Location
        }
        const res: any = await organisationService.updateOrganizationBannerAndAvatar(data, id);
        if (res.statusCode == 200) {
            const temp: any = { ...organization }
            temp.banner = data.banner;
            setOrganization(temp)
            setSelectedBannerFile(null);
            Toast.fire({
                icon: 'success',
                title: t('bannerUpdated')
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setIsloading(false);
    }
    const uploadAvatarImage = async () => {
        setIsloading(true);
        let data: IOrganizationBannerAvatar = {
        };
        if (selectedAvatarFile) {
            const params: S3.PutObjectRequest = {
                Body: selectedAvatarFile,
                Bucket: AWS_S3_BUCKET!,
                Key: `${process.env.REACT_APP_ENV}/${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedAvatarFile.name}`,
                ContentType: selectedAvatarFile.type,
                // ACL: "public-read",
            };
            const uploadResult: any = await s3.upload(params).promise();
            data.avatar = uploadResult.Location
        }
        const res: any = await organisationService.updateOrganizationBannerAndAvatar(data, id);
        if (res.statusCode == 200) {
            const temp: any = { ...organization }
            temp.avatar = data.avatar;
            setOrganization(temp)
            setSelectedAvatarFile(null);
            Toast.fire({
                icon: 'success',
                title: t('avatarUpdated')
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setIsloading(false);
    }
    const setOrganizationRole = (isCreate: boolean, data: IOrganizationRole) => {
        if (isCreate) {
            setOrganizationRoles([...organizationRoles!, data])
        } else {
            const tempindex = organizationRoles!.findIndex(role => role._id == data._id)
            if (data.isDefault === EBoolean.YES)
            {
                for (const item of organizationRoles!) {
                    item.isDefault = EBoolean.NO
                }
                data.isDefault = EBoolean.YES
            }
            organizationRoles!.splice(tempindex, 1, data);
            setOrganizationRoles([...organizationRoles!])
        }
    }
    const deleteOrganizationRole = (index: number) => {
        organizationRoles!.splice(index, 1);
        setOrganizationRoles([...organizationRoles!])
    }
    const closeImageCropper = () => {
        setSelectedBannerFile(null)
        setShowImageCropper(false);
    }
    const getCroppedImage = (image: any, blob: any) => {
        const file = new File([blob], selectedBannerFile.name)
        setBannerPreview(image);
        setShowImageCropper(false);
        setTimeout(() => {
            uploadBannerImage(file)
        }, 250)
    }
    const updateOrg = (org: IOrganization) => {
        setOrgName(org.name);
        setOrganization(org)
    }

    const sendFollowRequest = async () => {
        setOpenModal(true);
    }
    const unfollowOrganization = () => {
        Swal.fire({
          title: t('unfollowOrganization'),
          text: t('areYouSure'),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async (result: any) => {
          if (result) {
            if (result.isConfirmed) {
                unFollowRequest()
            }
          }
        });
    }
    const cancelFollowRequest = async () => {
        const data: IFollowOrganizationRequestStatusIds = {
            status: 'REJECTED',
            organizationId: organization?._id!,
            userId: currentUser._id
        }
        if (myRole.role.role === "Admin" && organizationAdmins.length === 1) {
            Toast.fire({
                icon: 'warning',
                title: t('onlyAdmin')
            })
            return;
        }
        const res: any = await organisationService.updateOrganizationFollowStatusIds(data);
        if (res.statusCode == 200) {
            Toast.fire({
                icon: 'success',
                title: res.message
            })
            window.location.reload();
            setIsMember('NO')
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const unFollowRequest = async () => {
        const data = {
            organization: organization?._id!
        }
        if (myRole.role.role === "Admin" && organizationAdmins.length === 1) {
            Toast.fire({
                icon: 'warning',
                title: t('onlyAdmin')
            })
            return;
        }
        const res: any = await organisationService.unfollowOrganization(data);
        if (res.statusCode == 201) {
            Toast.fire({
                icon: 'success',
                title: res.message
            })
            window.location.reload();
            setIsMember('NO')
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const closeModal = (data: IOrganizationRequestedMembers) => {
        setOpenModal(false)
        window.location.reload();
        if (data) {
            setMyRole(data);
            setIsMember(data.status)
        }
    }
    
    return (
        <AppLayout>
            <div className="up-ownorganisationprofilepage">
                {isLoading && <FullPageLoader />}
                {showImageCropper && <ImageCropper image={selectedBannerFile!} closeModal={closeImageCropper} croppedImage={getCroppedImage} />}
                <div className="up-themebanner">
                    {!selectedBannerFile && <figure className="up-bannerimg">
                        <img src={organization?.banner ?? images.RestPlaceholder} alt="Profile Banner Image" />
                    </figure>}
                    {selectedBannerFile && <figure className="up-bannerimg">
                        <img src={bannerPreview} alt="Profile Banner Image" />
                    </figure>}
                    <div className="up-profilebannercontent">
                        {selectedBannerFile && <>
                            {myRole && myRole?.role?.role === 'Admin' && <div className="up-changebannerbtn at-confirmbuttonsholder">
                                <label style={{ marginRight: "4px" }} onClick={onBannerImageClick}><i className="icon-camera" /></label>
                                <label onClick={uploadBannerImage}><i className="icon-checkmark" /></label>
                                <input accept="image/*" type="file" ref={bannerRef} onChange={bannerImageChange} style={{ display: "none" }} />
                            </div>}
                        </>}
                        {!selectedBannerFile && <>
                            {myRole && myRole?.role?.role === 'Admin' && <div className="up-changebannerbtn">
                                <input accept="image/*" onChange={bannerImageChange} type="file" name="change-banner" id="up-changeimage" />
                                <label htmlFor="up-changeimage">{t('editBanner')}</label>
                            </div>}
                        </>}
                        <div className="up-profilenameimage">
                            <figure className="up-organisationprofileimg">
                                {!selectedAvatarFile && <img src={organization?.avatar ?? images.RestPlaceholder} alt="Organisation Profile Image" />}
                                {selectedAvatarFile && <img src={avatarPreview} alt="Organisation Profile Image" />}

                                {selectedAvatarFile && <>
                                    {myRole && myRole?.role?.role === 'Admin' && <div className="up-uploadprofilepic at-changeprofilebtns">
                                        <input accept="image/*" type="file" ref={avatarRef} onChange={avatarImageChange} style={{ display: "none" }} />
                                        <label onClick={onAvatarImageClick} ><i className="icon-camera" /></label>
                                        <label className="up-checkbutton" onClick={uploadAvatarImage} ><i className="icon-checkmark" /></label>
                                    </div>}
                                </>}
                                {!selectedAvatarFile && <>
                                    {myRole && myRole?.role?.role === 'Admin' && <div className="up-uploadprofilepic">
                                        <input accept="image/*" onChange={avatarImageChange} type="file" name="upload-profile" id="up-uploadprofile" />
                                        <label htmlFor="up-uploadprofile"><i className="icon-camera" /></label>
                                    </div>}
                                </>}

                            </figure>
                            <div className="up-organisationname">
                                <h3>{orgName} <span className="up-verifedbadge">{(organization?.subscription?.proAccount && organization?.subscription?.proAccount == "YES") && (<Badge pill><img src={images.Veifed} alt="verifed badge" /></Badge>) }</span></h3>
                                <span>{organization?.type.name}</span>
                                
                            </div>
                            <div style={{ marginLeft: '18px' }}>
                                {isMember === 'NO' &&
                                    <div onClick={sendFollowRequest} className="up-cardtbns">
                                        <a href="javascript:void(0);" className="up-btn">{t('follow')}</a>
                                    </div>
                                }
                                {isMember === 'ACCEPTED' &&
                                    <div onClick={unfollowOrganization} className="up-cardtbns">
                                        <a href="javascript:void(0);" className="up-btn">{t('unfollow')}</a>
                                    </div>
                                }
                                {isMember === 'PENDING' &&
                                    <div onClick={cancelFollowRequest} className="up-cardtbns">
                                        <a href="javascript:void(0);" className="up-btn up-btnfollowrequestsend">{t('requestSent')}</a>
                                    </div>}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="up-organiserprofiletabholder">
                    {opneModal && <SendFollowRequestModal closeModal={closeModal} id={organization?._id!} isEdit={false} />}
                    {organization && currentTab && <OwnOrganisationProfileTabs setOrganizationAdmins={setOrganizationAdmins} setOrganization={updateOrg} currentTab={currentTab!} deleteOrganizationRole={deleteOrganizationRole} organization={organization!} myRole={myRole} organizationRoles={organizationRoles!} setOrganizationRole={setOrganizationRole} />}
                </div>
            </div>
        </AppLayout>
    )
}