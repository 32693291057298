import React, {useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";

const languages = [
    { label: "English", code: "en" },
    { label: "Dutch", code: "nl" },
    { label: "Romanian", code: "ro" },
    { label: "Polish", code: "pl" },
    { label: "French", code: "fr" },
    { label: "Italian", code: "it" },
    { label: "Swedish", code: "sv" },
    { label: "Portuguese", code: "pt" },
    { label: "Norwegian", code: "nb" },
];
export const ChangeLanguageModal: React.FC<any> = (props) => {
    const {t, i18n} = useTranslation('', {keyPrefix: 'NavBar'});
    const currentLanguage = i18n.language.split('-')[0];
    const handleClose = () => {
        props.closeModal()
    }
    const changeLanguage = (e: any) => {
        i18n.changeLanguage(e.target.value);
    }
    return <Modal
        className="up-thememodal up-modalreport"
        show={true}
        onHide={handleClose}
    >
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('changeLanguage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formreport">
                    <fieldset>
                        <ul className="up-reportoptions">
                            {languages.map((language) => (
                                <li key={language.code}>
                                    <div className="up-reportoption">
                                        <span>{language.label}</span>
                                        <div className="up-radio">
                                            <input
                                                type="radio"
                                                name="language-option"
                                                id={`language-${language.code}`}
                                                value={language.code}
                                                checked={currentLanguage === language.code}
                                                onChange={changeLanguage}
                                            />
                                            <label htmlFor={`language-${language.code}`}></label>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}
