import React from "react";
import ReactDOM from "react-dom";
import "./App.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ScrollToTop } from "./utils/scroll-to-top";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import './i18n/config';
import SecurityScript from "./components/SecurityScript";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <SecurityScript 
        config={{
          enableConsoleWarning: true,
          disableConsole: true,
          preventDevTools: true,
          preventRightClick: false,
          preventKeyboardShortcuts: true,
          preventCopying: false,
          enableSourceProtection: true,
          enableStorageProtection: true

        }}
      />
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
