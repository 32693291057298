import {yupResolver} from '@hookform/resolvers/yup';
import S3 from 'aws-sdk/clients/s3';
import moment from 'moment';
import {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useFieldArray, useForm} from 'react-hook-form';
import {useParams} from 'react-router';
import {images} from '../../../assets/images';
import {AWS_S3_BUCKET, s3} from '../../../config/aws.config';
import {IIdFromUrl} from '../../../interfaces/id-from-url.interface';
import {eventService} from '../../../services/events.service';
import {Toast} from '../../../utils/Toast';
import {ICreateEventModalProps} from '../../organisations/createorganisation/interfaces/create-event-modal.interface';
import {ICreateEvent} from '../../organisations/createorganisation/interfaces/create-event.interface';
import {
    IOrganizationRole
} from '../../organisations/createorganisation/interfaces/organization-requested-members.interface';
import {createEventSchema} from '../../organisations/createorganisation/validations/create-event.validation';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {EEventNotificationType} from '../../../interfaces/notification-type.enum';
import {groupService} from '../../../services/group.service';
import {IGroup} from '../../organisations/createorganisation/interfaces/group.interface';
import {PostDisplayToModal} from './PostDisplayToModal';
import {EWhichDisplayTo} from '../../organisations/createorganisation/interfaces/display-to-enum';
import DatePicker from "react-datepicker";
import {EBoolean} from "../../auth/pages/signup/interfaces/drop-down.enums";
import {useTranslation} from "react-i18next";

const API_KEY = process.env.GOOGLE_API_KEY;

export const CreateEventModal: React.FC<ICreateEventModalProps> = (props) => {
    const [showCreateEvent, setShowCreateEvent] = useState(true);
    const [showDisplayToModal, setShowDisplayToModal] = useState(false);
    const [isPublicChecked, setIsPublicChecked] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [notificationHours, setNotificationHours] = useState<number[]>([])
    const [selectedEventFile, setSelectedEventFile] = useState<any>()
    const [eventPreview, setEventPreview] = useState<any>()
    const [placesValue, setPlacesValue] = useState<any>();
    const [location, setLocation] = useState<string>(props.isEdit ? props.editEvent.location : '');
    const [groupsForPostCreation, setGroupsForPostCreation] = useState<IGroup[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([])
    const [organizationRoles, setOrganizationRoles] = useState<IOrganizationRole[]>(props.organizationRoles)
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [joinDate, setJoinDate] = useState<any>();
    const { t } = useTranslation('', { keyPrefix: 'CreateEventModal' });

    let { id } = useParams<IIdFromUrl>();
    const { control, register, setError, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(createEventSchema)
    });
    const { fields: hourFields, append: hourAppend, remove: hourRemove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "notificationHours", // unique name for your Field Array
    });
    const canAnswerUntilEndDate = watch("canAnswerUntilEndDate");
    const isAllDayEvent = watch("isAllDayEvent");
    useEffect(() => {
        setData()
    }, [])

    const setData = async () => {
        const res: any = await getGroupsForPostCreation();
        if (props.isEdit) {
            setValue('name', props.editEvent?.name)
            setValue('url', props.editEvent?.url)
            setValue('ticket', props.editEvent?.ticket)
            setValue('location', props.editEvent?.location)
            setValue('isSubscriptionNeeded', props.editEvent?.isSubscriptionNeeded  === EBoolean.YES)
            setValue('canAnswerUntilEndDate', props.editEvent?.canAnswerUntilEndDate)
            setValue('isAllDayEvent', props.editEvent?.isAllDayEvent === EBoolean.YES)
            const strippedString = props.editEvent?.description.replace(/(<([^>]+)>)/gi, "");
            setValue('description', strippedString);
            const startTime = new Date(props.editEvent!.startDate!);
            const endTime = new Date(props.editEvent!.endDate!);
            setValue('startDate', props.editEvent?.startDate.split('T')[0])
            setStartDate(new Date(props.editEvent?.startDate))
            setEndDate(new Date(props.editEvent?.endDate))
            setJoinDate(new Date(props.editEvent?.joiningEndDate))
            setValue('startTime', String(startTime.getHours()).padStart(2, '0') + ':' + String(startTime.getMinutes()).padStart(2, '0'));
            setValue('endTime', String(endTime.getHours()).padStart(2, '0') + ':' + String(endTime.getMinutes()).padStart(2, '0'));
            setValue('endDate', props.editEvent?.endDate.split('T')[0])
            setValue('joiningEndDate', props.editEvent?.joiningEndDate.split('T')[0])
            setValue('notificationType', props.editEvent.notificationType)
            setNotificationHours(props.editEvent.notificationHours)
            const temp = props.editEvent.notificationHours.map(item => {
                return { value: item }
            });
            setValue('notificationHours', temp)
            if (props?.editEvent?.displayTo?.length == 0) {
                setSelectedRoles([]);
                setSelectedGroups([]);
                setIsPublicChecked(true);
            } else {
                const tempRoles: string[] = []
                if (props.editEvent.eWhichDisplayTo === EWhichDisplayTo.OrganizationRole) {
                    for (const iterator of props.editEvent.displayTo) {
                        const objIndex = organizationRoles.findIndex(role => role._id == (iterator?._id || iterator))
                        if (objIndex != -1) {
                            organizationRoles[objIndex].checked = true;
                            tempRoles.push((iterator?._id || iterator).toString());
                        }
                    }
                    setGroupsForPostCreation([...res])
                    setSelectedRoles(tempRoles);
                } else if (props.editEvent.eWhichDisplayTo === EWhichDisplayTo.Group) {
                    const tempData: any = await getGroupsForPostCreation()
                    for (const iterator of props.editEvent.displayTo) {
                        const objIndex = tempData.findIndex((role: any) => role._id == (iterator._id || iterator))
                        if (objIndex != -1) {
                            tempData[objIndex].checked = true;
                            tempRoles.push((iterator._id || iterator).toString());
                        }
                    }
                    setGroupsForPostCreation([...tempData])
                    setSelectedGroups(tempRoles);
                }
                setIsPublicChecked(false)
            }
        }
    }
    useEffect(() => {
        if (!selectedEventFile) {
            setEventPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedEventFile)
        setEventPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedEventFile])


    const handleCloseeight = () => {
        props.closeModal(true)
    }

    const createEventSubmit = async (data: ICreateEvent) => {
        if (!startDate) {
            Toast.fire({
                icon: 'warning',
                title: t('startDateRequired')
            })
            return;
        }
        if (!endDate) {
            Toast.fire({
                icon: 'warning',
                title: t('endDateRequired')
            })
            return;
        }
        if (canAnswerUntilEndDate == EBoolean.YES && !joinDate) {
            Toast.fire({
                icon: 'warning',
                title: t('joinDateRequired')
            })
            return;
        }
        let isError = false
        data.notificationHours = data.notificationHours!.map(hour => hour.value).filter((item, index) => {
            if (item && item.length > 0) {
                if (moment(    startDate.toLocaleDateString() + (data?.startTime || '')).subtract(+item, 'hours').isBefore(new Date())) {
                    setError(`notificationHours.${index}.value`, { type: "required", message: 'Notification Hour cannot be in past' }, { shouldFocus: true });
                    isError = true;
                }
                return +item
            } else {
                return false;
            }
        }).map(item => +item)
        if (isError) {
            return
        }
        data.isSubscriptionNeeded  = data.isSubscriptionNeeded  ? 'YES' : 'NO';
        data.isAllDayEvent  = data.isAllDayEvent  ? 'YES' : 'NO';
        data.startDate = startDate
        data.endDate = endDate ?? startDate
        data.joiningEndDate = joinDate || data.endDate
        if (data?.url && data?.url.slice(0, 3) == 'www') {
            data.url = 'https://' + data?.url;
        }
        if (data?.ticket && data?.ticket.slice(0, 3) == 'www') {
            data.ticket = 'https://' + data?.ticket;
        }
        if (placesValue) {
            const results = await geocodeByAddress(placesValue.label);
            const latLng = await getLatLng(results[0]);
            data.lat = latLng.lat;
            data.long = latLng.lng;
            data.location = results[0].formatted_address;
        } else if (props.isEdit) {
            if (!placesValue) {
                data.lat = +props.editEvent.lat;
                data.long = +props.editEvent.long;
                data.location = props.editEvent.location;
            } else if (location) {
                data.location = location;
            }
        } else if (location) {
            data.location = location;
        } else {
            Toast.fire({
                icon: 'warning',
                title: t('locationRequired')
            })
            return;
        }

        if (props.isEdit) {
            data.notificationHours = [...data.notificationHours, ...notificationHours]
        }
        if (!data.notificationType) {
            delete data.notificationType;
            delete data.notificationHours;
        }
        if (data.startTime) {
            data.startDate.setHours(data.startTime.split(':')[0]);
            data.startDate.setMinutes(data.startTime.split(':')[1]);
        } else {
            data.startDate.setHours('00');
            data.startDate.setMinutes('00');
        }
        if (data.endTime) {
            data.endDate.setHours(data.endTime.split(':')[0])
            data.endDate.setMinutes(data.endTime.split(':')[1]);
        } else {
            data.endDate.setHours('23')
            data.endDate.setMinutes('59');
        }
        setIsLoading(true);
        data.organization = id;
        if (isPublicChecked) {
            data.displayTo = []
        } else if (selectedRoles.length > 0) {
            data.displayTo = [...selectedRoles]
            data.eWhichDisplayTo = EWhichDisplayTo.OrganizationRole
        } else if (selectedGroups.length > 0) {
            data.displayTo = [...selectedGroups]
            data.eWhichDisplayTo = EWhichDisplayTo.Group
        }
        if (selectedEventFile) {
            const params: S3.PutObjectRequest = {
                Body: selectedEventFile,
                Bucket: AWS_S3_BUCKET!,
                Key: `${process.env.REACT_APP_ENV}/${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedEventFile.name}`,
                ContentType: selectedEventFile.type,
                // ACL: "public-read"
            };
            const uploadResult: any = await s3.upload(params).promise();
            data.avatar = uploadResult.Location
        }
        if (props.isEdit) {
            const res: any = await eventService.updateEvent(props.editEvent?._id!, data);
            if (res.statusCode == 200) {
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
                setIsLoading(false);
                props.eventCreated(res.payload.event, false)
                setShowCreateEvent(false);
                setSelectedRoles([]);
                setSelectedEventFile(undefined);
                setEventPreview(undefined);
                props.closeModal(true)
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        } else {
            const res: any = await eventService.createEvent(data);
            if (res.statusCode == 201) {
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
                setIsLoading(false);
                props.eventCreated(res.payload.event, true)
                setShowCreateEvent(false);
                setSelectedRoles([]);
                setSelectedEventFile(undefined);
                setEventPreview(undefined);
                props.closeModal(true)
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }

    const getGroupsForPostCreation = async () => {
        const res: any = await groupService.getOrganizationGroups(id, 1, ' ', 1000);
        if (res.statusCode == 200) {
            for (const group of res.payload.groups.records) {
                group.childGroups = [];
                group.checked = false;
            }
            if (!props.isEdit || props?.editEvent?.displayTo.length === 0) {
                setGroupsForPostCreation([...res.payload.groups.records])
            }
            return [...res.payload.groups.records]
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const closeDisplayToModal = () => {
        setShowDisplayToModal(false);
        setShowCreateEvent(true);
    }
    const publicChangeHandler = (event: any) => {
        if (event.target.checked) {
            setSelectedRoles([]);
            for (const iterator of props.organizationRoles) {
                iterator.checked = false;
            }
            setOrganizationRoles([...organizationRoles])
        }
        setIsPublicChecked(!isPublicChecked)
    }
    const handleRoleSelection = (event: any, role: IOrganizationRole, index: number, type: string) => {
        role.checked = event.target.checked
        switch (type) {
            case 'ROLE':
                if (event.target.checked) {
                    const temp: string[] = []
                    temp.push(event.target.id)
                    if (selectedRoles) {
                        setSelectedRoles([...selectedRoles, ...temp])
                    } else {
                        setSelectedRoles([...temp])
                    }
                } else {
                    const temp = selectedRoles!.findIndex((role) => role === event.target.id)
                    if (temp != -1) {
                        selectedRoles!.splice(temp, 1)
                        setSelectedRoles([...selectedRoles!])
                    }
                }
                for (const iterator of groupsForPostCreation) {
                    iterator.checked = false;
                }
                setGroupsForPostCreation([...groupsForPostCreation])
                setSelectedGroups([]);
                break;
            case 'GROUP':
                if (event.target.checked) {
                    const temp: string[] = []
                    temp.push(event.target.id)
                    if (selectedGroups) {
                        setSelectedGroups([...selectedGroups, ...temp])
                    } else {
                        setSelectedGroups([...temp])
                    }
                } else {
                    const temp = selectedGroups!.findIndex((role) => role === event.target.id)
                    if (temp != -1) {
                        selectedGroups!.splice(temp, 1)
                        setSelectedGroups([...selectedGroups!])
                    }
                }
                for (const iterator of organizationRoles) {
                    iterator.checked = false;
                }
                setOrganizationRoles([...organizationRoles])
                setSelectedRoles([])
                break;
            default:
                break;
        }
    }
    const showDisplayModal = () => {
        setShowCreateEvent(false);
        setShowDisplayToModal(true);
    }
    const eventImageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedEventFile(e.target.files[0]);
        }
    };

    const deleteOption = (index: number) => {
        hourRemove(index)
        if (props.isEdit) {
            notificationHours.splice(index, 1);
            setNotificationHours([...notificationHours])
        }
    };

    const addOption = () => {
        hourAppend({ notificationHours: 0 });
    };

    const handleInputChange = (input: string) => {
        if (input) {
            setLocation(input);
        }
    }

    const handleStartDateChange = (data: any) => {
        setStartDate(data)
    };
    const handleEndDateChange = (data: any) => {
        setEndDate(data)
    };
    const handleJoinDateChange = (data: any) => {
        setJoinDate(data)
    };
    return <> <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={showCreateEvent} onHide={handleCloseeight}>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreateevent" onSubmit={handleSubmit(createEventSubmit)}>
                    <fieldset>
                        <div className="up-createeventcontent">
                            <div className="up-gourpprofileimage">
                                <figure className="up-groupprofileimg">
                                    <div className="up-uploadgroupimg">
                                        <input accept="image/*" onChange={eventImageChange} type="file" name="upload-profile-img" id="up-uploadgroupprofileimg" />
                                        <label htmlFor="up-uploadgroupprofileimg"><i className="icon-camera"></i></label>
                                    </div>
                                    {eventPreview && <img src={eventPreview} alt="Group Profile Image" />}
                                    {!eventPreview && <img src={props.editEvent?.avatar ?? images.RestPlaceholder} alt="Group Profile Image" />}
                                </figure>
                            </div>
                            <div className="form-group">
                                <label>{t('eventName')}</label>
                                <input  {...register("name")} type="text" name="name" className="form-control" placeholder={t('name')} />
                                <small id="content" className="form-text text-muted">
                                    {errors.name?.message}
                                </small>
                            </div>
                            <div className="up-advanceoption">
                                <span>{t('isAllDayEvent')}</span>
                                <div className="up-togglebutton">
                                    <input {...register("isAllDayEvent")} type="checkbox" name="isAllDayEvent" id="up-isAllDayEvent" />
                                    <label htmlFor="up-isAllDayEvent"></label>
                                </div>
                            </div>
                            <div className="up-formtwocols">
                                <div className="up-inputsholder">
                                    <label className='d-block'>{t('startDate')}</label>
                                    {/* <input {...register("startDate")} type="date" name="startDate" className="form-control" placeholder="Date &amp; Time" /> */}
                                    <DatePicker
                                        {...register("startDate")}
                                        selected={startDate}
                                        placeholderText="yyyy/mm/dd"
                                        minDate={moment().toDate()}
                                        onChange={handleStartDateChange}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    <small id="content" className="form-text text-muted">
                                        {errors.startDate?.message}
                                    </small>
                                </div>
                                <div className="up-inputsholder">
                                    <label className='d-block'>{t('endDate')}</label>
                                    {/* <input {...register("endDate")} type="date" name="endDate" className="form-control" placeholder="Date &amp; Time" /> */}
                                    <DatePicker
                                        {...register("endDate")}
                                        selected={endDate}
                                        minDate={startDate}
                                        placeholderText="yyyy/mm/dd"
                                        onChange={handleEndDateChange}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    <small id="content" className="form-text text-muted">
                                        {errors.endDate?.message}
                                    </small>
                                </div>
                            </div>
                            {!isAllDayEvent && <div className="up-formtwocols">
                                <div className="up-inputsholder">
                                    <label>{t('startTime')}</label>
                                    <input {...register("startTime")} type="time" name="startTime"
                                           className="form-control" placeholder={t('startTime')}/>
                                    <small id="content" className="form-text text-muted">
                                        {errors.startTime?.message}
                                    </small>
                                </div>
                                <div className="up-inputsholder">
                                    <label>{t('endTime')}</label>
                                    <input {...register("endTime")} type="time" name="endTime" className="form-control"
                                           placeholder={t('endTime')}/>
                                    <small id="content" className="form-text text-muted">
                                        {errors.endTime?.message}
                                    </small>
                                </div>
                            </div>}
                            <div className="form-group">
                                <label>{t('limitedTimeAnswer')}</label>
                                <div className='up-select up-zindexzero'>
                                    <select {...register("canAnswerUntilEndDate")} name="canAnswerUntilEndDate">
                                        <option value="" selected disabled>{t('selectOption')}</option>
                                        <option value={EBoolean.YES}>{t('yes')}</option>
                                        <option value={EBoolean.NO}>{t('no')}</option>
                                    </select>
                                    <small id="content" className="form-text text-muted">
                                        {errors.canAnswerUntilEndDate?.message}
                                    </small>
                                </div>
                            </div>
                            {canAnswerUntilEndDate === 'YES' && <div className="form-group">
                                <label>{t('answerPossibleUntil')}</label>
                                <DatePicker
                                    {...register("joiningEndDate")}
                                    selected={joinDate}
                                    minDate={moment().toDate()}
                                    placeholderText="yyyy/mm/dd"
                                    showTimeSelect
                                    onChange={handleJoinDateChange}
                                    dateFormat="yyyy-MM-dd hh:mm"
                                />
                                <small id="content" className="form-text text-muted">
                                    {errors.joiningEndDate?.message}
                                </small>
                            </div>}
                            <div className="form-group">
                                <ul className="up-reportoptions">
                                    <li>
                                        <div className="up-reportoption">
                                            <span>{t('subscriptionRequiredToJoin')}</span>
                                            <div className="up-togglebutton">
                                                <input {...register("isSubscriptionNeeded")} type="checkbox" name="isSubscriptionNeeded" id="up-isSubscriptionNeeded" />
                                                <label htmlFor="up-isSubscriptionNeeded"></label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group">
                                <label>{t('location')}</label>
                                <div className='up-haslayout up-eventlocationinput'>
                                    <GooglePlacesAutocomplete
                                        apiKey={API_KEY}
                                        debounce={300}
                                        selectProps={{
                                            defaultInputValue: location,
                                            isClearable: true,
                                            value: placesValue,
                                            onChange: setPlacesValue,
                                            onInputChange: handleInputChange,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t('notificationType')}</label>
                                <div className='up-select up-zindexzero'>
                                    <select {...register("notificationType")} onChange={(e) => {
                                        if (hourFields.length == 0) {
                                            hourAppend({ notificationHours: 1 })
                                        }
                                    }}>
                                        <option value="" selected>{t('selectOption')}</option>
                                        <option value={EEventNotificationType.EMAIL}>{t('email')}</option>
                                        <option value={EEventNotificationType.IN_APP_NOTIFICATION}>{t('inAppNotification')}</option>
                                    </select>
                                    <small id="content" className="form-text text-muted">
                                        {errors.notificationType?.message}
                                    </small>
                                </div>
                            </div>
                            <div className="form-group">
                                {hourFields.map((date, index) => (
                                    <div key={date.id}>
                                        <div className="form-group up-inputwithicon">
                                            <input id="value" type="number" {...register(`notificationHours.${index}.value`)} min="1" className="form-control" placeholder={t('enterHour')} />
                                            <i className='icon-cross' onClick={() => deleteOption(index)}></i>
                                            {errors.notificationHours?.[index]?.value && <small id="emailHelp" className="form-text text-muted">
                                                {t('notificationHourCannotPast')}
                                            </small>}
                                        </div>
                                    </div>
                                ))}
                                <button type="button" onClick={addOption} className="up-btn up-btnwithloader">{t('addNotification')}</button>
                            </div>
                            <div className="form-group">
                                <label>{t('description')}</label>
                                <textarea {...register("description")} name="description" placeholder={t('writeAboutEvent')} />
                                <small id="content" className="form-text text-muted" style={{ float: 'right' }}>
                                    {errors.description?.message}
                                </small>
                            </div>
                            <div className="form-group">
                                <label>{t('eventURL')}</label>
                                <input  {...register("url")} type="text" name="url" className="form-control" placeholder={t('eventURL')} />

                            </div>
                            <div className="form-group">
                                <label>{t('eventTicketsURL')}</label>
                                <input  {...register("ticket")} type="text" name="ticket" className="form-control" placeholder={t('eventTicketsURL')} />
                            </div>
                            <div className="form-group">
                                <label>{t('eventDisplayTo')}</label>
                            <span onClick={() => showDisplayModal()} className="up-select">
                                    {isPublicChecked ? t('public') : t('custom')}
                                </span>
                            </div>
                            <button disabled={isLoading} type="submit" className="up-btn up-btnwithloader">{props?.isEdit ? t('update') : t('create')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
        {showDisplayToModal && <PostDisplayToModal hideRoles={false} organizationGroups={groupsForPostCreation} organizationRoles={organizationRoles} publicChangeHandler={publicChangeHandler} isPublicChecked={isPublicChecked} closeModal={closeDisplayToModal} handleRoleSelection={handleRoleSelection} />}
    </>
}
